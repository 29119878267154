import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ContainerHeader from 'components/ContainerHeader/index';
import ServiceTable from 'app/routes/services/Components/ServiceTable';
import ServiceForm from 'app/routes/services/Components/ServiceForm';

export default class Services extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader match={this.props.match} title="Services" />
          <Switch>
            <Route path={`${match.url}/new`} component={ServiceForm} />
            <Route path={`${match.url}/:serviceId`} component={ServiceForm} />
            <Route path={`${match.url}/`} component={ServiceTable} />
          </Switch>
        </div>
      </div>
    );
  }
}
