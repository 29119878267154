import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ContainerHeader from 'components/ContainerHeader/index';
import OptionTable from 'app/routes/options/Components/OptionTable';
import OptionForm from 'app/routes/options/Components/OptionForm';

export default class Options extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader match={match} title="Options" />
          <Switch>
            <Route path={`${match.url}/new`} component={OptionForm} />
            <Route path={`${match.url}/:optionId`} component={OptionForm} />
            <Route path={`${match.url}/`} component={OptionTable} />
          </Switch>
        </div>
      </div>
    );
  }
}
