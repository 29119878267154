import {
  FILTER_CONTACT,
  FILTER_USERS,
  ON_HIDE_LOADER,
  ON_SELECT_USER,
  ON_TOGGLE_DRAWER,
  SUBMIT_COMMENT,
  UPDATE_MESSAGE_VALUE,
  UPDATE_SEARCH_CHAT_USER,
  USER_INFO_STATE
} from 'actions/ActionType';


export const filterContacts = userName => ({
  type: FILTER_CONTACT,
  payload: userName
});

export const filterUsers = userName => ({
  type: FILTER_USERS,
  payload: userName
});


export const onSelectUser = user => ({
  type: ON_SELECT_USER,
  payload: user
});

export const submitComment = () => ({
  type: SUBMIT_COMMENT,
});

export const hideLoader = () => ({
  type: ON_HIDE_LOADER,
});

export const userInfoState = state => ({
  type: USER_INFO_STATE,
  payload: state
});

export const updateMessageValue = message => ({
  type: UPDATE_MESSAGE_VALUE,
  payload: message
});


export const updateSearchChatUser = userName => ({
  type: UPDATE_SEARCH_CHAT_USER,
  payload: userName
});
export const onChatToggleDrawer = () => ({
  type: ON_TOGGLE_DRAWER
});
