import { format, subDays } from 'date-fns';
/**
 *
 * @param name {String} property name
 * @param objectData
 */
export function propsAvailable(name, objectData) {
  return Object.prototype.hasOwnProperty.call(objectData, name);
}


export function isEmpty(val, str = false, obj = true) {
  let empty = val === null || val === undefined;
  if (!empty && str === true) {
    empty = val.trim() === '';
  }

  if (!empty && obj === true) {
    empty = Object.keys(val).length === 0 && val.constructor === Object;
  }
  return empty;
}

// tslint:disable-next-line:no-shadowed-constiable
export function propExist(obj, props, depth, max) {
  // props[depth] is each array of properties to check in the object
  // obj[props[depth]] Get the value of the props
  const exist = isEmpty(obj) ? false : propsAvailable(props[depth], obj);
  // Check if the propert exist in the object
  if (!exist) {
    //  if it does not exist return false
    return false;
  }
  const newObj = obj[props[depth]];
  // Go to the child property
  const newDepth = depth + 1;
  // If the depth is attain return false
  // Else check if the child property exist
  return newDepth === max ? true : propExist(newObj, props, newDepth, max);
}
export function deepPropsExist(obj, ...props) {
  if (isEmpty(obj) || Object.prototype.toString.call(obj) !== '[object Object]') {
    return null;
  }

  const depth = props.length;
  return propExist(obj, props, 0, depth);
}

export function getObjValue(obj, props, depth, max) {
  // props[depth] is each array of properties to check in the object
  // obj[props[depth]] Get the value of the props
  const exist = isEmpty(obj) ? false : propsAvailable(props[depth], obj);
  // Check if the propert exist in the object
  if (!exist) {
    //  if it does not exist return false
    return null;
  }
  const newObj = obj[props[depth]];
  // Go to the child property
  const newDepth = depth + 1;
  // If the depth is attain return false
  // Else check if the child property exist
  return newDepth === max ? newObj : getObjValue(newObj, props, newDepth, max);
}

export function getDeepObjValue(obj, ...props) {
  if (isEmpty(obj) || Object.prototype.toString.call(obj) !== '[object Object]') {
    return null;
  }
  const max = props.length;
  return getObjValue(obj, props, 0, max);
}

// Compare two items
function compare(item1, item2) {

  // Get the object type
  const itemType = Object.prototype.toString.call(item1);

  // If an object or array, compare recursively
  if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
    // eslint-disable-next-line no-use-before-define
    if (!isEqual(item1, item2)) {
      return false;
    }
  } else { // Otherwise, do a simple comparison

    // If the two items are not the same type, return false
    if (itemType !== Object.prototype.toString.call(item2)) {
      return false;
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (itemType === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        return false;
      }
    } else if (item1 !== item2) {
      return false;
    }
  }
  return true;
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}


export function isEqual(value, other) {
  // Get the value type
  const type = Object.prototype.toString.call(value);
  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) {
    return false;
  }

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
    return false;
  }

  // Compare the length of the length of the two items
  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) {
    return false;
  }

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) {
        return false;
      }
    }
  } else {
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-restricted-syntax
    for (const key in value) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) {
          return false;
        }
      }
    }
  }

  // If nothing failed, return true
  return true;

}

/**
 * @description Formats number with commas
 * @example formatNumber("20000")
 *  // "20,000"
 * @param {*} num
 * @returns {string}
 */
export function formatNumber(number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * @description Create object representing x(defult = 15) days away and today
 * @returns {object} object with `startDate` and `endDate` representing x|15 days away and today
 */
export function makeStartEndDate(daysAway = 15) {
  const currentDate = new Date();
  const last15Days = subDays(currentDate, daysAway);
  return {
    startDate: format(last15Days, 'yyyy-MM-dd'),
    endDate: format(currentDate, 'yyyy-MM-dd')
  };
}

/**
 * @description Turns an array of object into a single object for easy access to values
 * @param {*} array An array of objects
 * @param {string} key A key whose value should be used as key in the new object
 * @param {*} value A key whose value should be used as value in the new object
 * @returns {object} a flateened array
 * @example
 *  const array = [
 *   { name : 'jude', age : 20},
 *   { name : 'alice', age : 80}
 *  ]
 * flatten(array, 'name', 'age')
 * //{ jude: 20, alice : 80}
 */
export function flatten(array, key, value) {
  let flateened = {};

  for (const element of array) {
    const newElement = {
      [element[key]]: element[value],
    };

    flateened = {
      ...flateened,
      ...newElement,
    };
  }

  return flateened;
}

/**
 * @description Calls predicate with all dates between startDate and endDate
 * @param {Date} startDate A date object
 * @param {Date} endDate A date object
 * @param {function} predicate a function that will be called with all dates between startDate and endDate
 */
export function forEachDateBetween(startDate, endDate, predicate) {
  const theDate = new Date(startDate);
  while (theDate < endDate) {
    predicate(theDate);
    theDate.setDate(theDate.getDate() + 1);
  }
  predicate(endDate);

  return Promise.resolve();
}

/**
 * @description Get all dates between startDate and endDate
 * @param {Date} startDate A date object
 * @param {Date} endDate A date object
 * @returns All dates between startDate and endDate
 */
export function getDatesBetweenDates(startDate, endDate) {
  let dates = [];

  const theDate = new Date(startDate);
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }
  dates = [...dates, endDate];
  return dates;
}