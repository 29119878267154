import React from 'react';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';

import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';

const Header = ({ onToggleCollapsedNav, drawerType }) => {
  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon ${drawerStyle}`}
          color="contrast"
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>
        <h2 className="mb-0">KliQr Admin</h2>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
