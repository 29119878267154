import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';

export default class TagTableCell extends PureComponent {
  state = {
    anchorEl: undefined,
    open: false,
  };

  openMenu = event => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  handleClick = e => {
    this.closeMenu();
    this.props.onStatusChange(e);
  };

  render() {
    const { tag } = this.props;
    let status, statusColor, statusText, actionText;

    if (tag.status == 1) {
      status = 0;
      statusColor = 'success';
      statusText = 'Active';
      actionText = 'Deactivate';
    } else {
      status = 1;
      statusColor = 'danger';
      statusText = 'Inactive';
      actionText = 'Activate';
    }

    return (
      <tr>
        <td>{tag.tag_name}</td>
        <td>{tag.parent_name ? tag.parent_name : 'No Parent'} </td>
        <td className="status-cell text-right">
          <Badge color={statusColor}>{statusText}</Badge>
        </td>
        <td className="text-right">
          <IconButton className="size-30" onClick={this.openMenu}>
            <i className="zmdi zmdi-more-vert" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.closeMenu}
            style={{ maxHeight: 40 * 4.5 }}
            MenuListProps={{ style: { width: 150 } }}
          >
            <Link style={{ textDecoration: 'none' }} to={`/app/tags/${tag.id}`}>
              <MenuItem onClick={this.triggerLink}>Edit</MenuItem>
            </Link>
            <MenuItem
              data-status={status}
              data-id={tag.id}
              onClick={this.handleClick}
            >
              {actionText}
            </MenuItem>
          </Menu>
        </td>
      </tr>
    );
  }
}
