import {DRAWER_TYPE, FIXED_DRAWER, THEME_COLOR, TOGGLE_COLLAPSED_NAV} from 'constants/ActionTypes';
import {DARK_INDIGO} from "constants/Constant";

const initialSettings = {
    navCollapsed: false,
    drawerType: FIXED_DRAWER,
    themeColor: DARK_INDIGO,
};

const settings = (state = initialSettings, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false
            };
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.isNavCollapsed
            };
        case DRAWER_TYPE:
            return {
                ...state,
                drawerType: action.drawerType
            };
        case THEME_COLOR:
            return {
                ...state,
                themeColor: action.color
            };
        default:
            return state;
    }
};

export default settings;
