export default [
    {
        'id': 1,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:08:35 PM',
            }, {
                'type': 'received',
                'message': 'I must explain to you how all this mistaken idea of denouncing ',
                'sentAt': 'Nov 22, 2017, 3:10:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
                'sentAt': 'Nov 22, 2017, 3:11:25 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s',
                'sentAt': 'Nov 22, 2017, 3:15:45 PM',
            }
        ]
    },
    {
        'id': 2,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:03:28 PM',
            },
            {
                'type': 'received',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:05:47 PM',
            },
            {
                'type': 'sent',
                'message': 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
                'sentAt': 'Nov 22, 2017, 3:07:52 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            },
        ]
    },
    {
        'id': 3,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:08:35 PM',
            }, {
                'type': 'received',
                'message': 'I must explain to you how all this mistaken idea of denouncing ',
                'sentAt': 'Nov 22, 2017, 3:10:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
                'sentAt': 'Nov 22, 2017, 3:11:25 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            },
        ]
    },
    {
        'id': 4,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:03:28 PM',
            },
            {
                'type': 'received',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:05:47 PM',
            },
            {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
                'sentAt': 'Nov 22, 2017, 3:11:25 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s',
                'sentAt': 'Nov 22, 2017, 3:15:45 PM',
            }
        ]
    }, {
        'id': 5,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
                'sentAt': 'Nov 22, 2017, 3:07:52 PM',
            }, {
                'type': 'sent',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:08:35 PM',
            }, {
                'type': 'received',
                'message': 'I must explain to you how all this mistaken idea of denouncing ',
                'sentAt': 'Nov 22, 2017, 3:10:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
                'sentAt': 'Nov 22, 2017, 3:11:25 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s',
                'sentAt': 'Nov 22, 2017, 3:15:45 PM',
            }
        ]
    },
    {
        'id': 6,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:08:35 PM',
            }, {
                'type': 'received',
                'message': 'I must explain to you how all this mistaken idea of denouncing ',
                'sentAt': 'Nov 22, 2017, 3:10:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
                'sentAt': 'Nov 22, 2017, 3:11:25 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s',
                'sentAt': 'Nov 22, 2017, 3:15:45 PM',
            }
        ]
    }, {
        'id': 7,
        'conversationData': [

            {
                'type': 'received',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:05:47 PM',
            },
            {
                'type': 'sent',
                'message': 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
                'sentAt': 'Nov 22, 2017, 3:07:52 PM',
            }, {
                'type': 'sent',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:08:35 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            },
        ]
    },
    {
        'id': 8,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:03:28 PM',
            },
            {
                'type': 'sent',
                'message': 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
                'sentAt': 'Nov 22, 2017, 3:07:52 PM',
            }, {
                'type': 'sent',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:08:35 PM',
            }, {
                'type': 'received',
                'message': 'I must explain to you how all this mistaken idea of denouncing ',
                'sentAt': 'Nov 22, 2017, 3:10:28 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s',
                'sentAt': 'Nov 22, 2017, 3:15:45 PM',
            }
        ]
    },
    {
        'id': 9,
        'conversationData': [

            {
                'type': 'received',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:05:47 PM',
            },
            {
                'type': 'sent',
                'message': 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
                'sentAt': 'Nov 22, 2017, 3:07:52 PM',
            },
            {
                'type': 'received',
                'message': 'I must explain to you how all this mistaken idea of denouncing ',
                'sentAt': 'Nov 22, 2017, 3:10:28 PM',
            },
            {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            },
            {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            },
            {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            },
            {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s',
                'sentAt': 'Nov 22, 2017, 3:15:45 PM',
            }
        ]
    },
    {
        'id': 10,
        'conversationData': [
            {
                'type': 'sent',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:03:28 PM',
            }, {
                'type': 'received',
                'message': 'English versions from the 1914 translation by H. Rackham',
                'sentAt': 'Nov 22, 2017, 3:05:47 PM',
            }, {
                'type': 'sent',
                'message': 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
                'sentAt': 'Nov 22, 2017, 3:07:52 PM',
            }, {
                'type': 'sent',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:08:35 PM',
            }, {
                'type': 'received',
                'message': 'I must explain to you how all this mistaken idea of denouncing ',
                'sentAt': 'Nov 22, 2017, 3:10:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
                'sentAt': 'Nov 22, 2017, 3:11:25 PM',
            }, {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            }, {
                'type': 'received',
                'message': 'It is a long established fact',
                'sentAt': 'Nov 22, 2017, 3:13:28 PM',
            }, {
                'type': 'sent',
                'message': 'The standard chunk of Lorem Ipsum used since the 1500s',
                'sentAt': 'Nov 22, 2017, 3:15:45 PM',
            }
        ]
    },
    {
        'id': 11,
        'conversationData': [
            {
                'type': 'received',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:12:36 PM',
            }, {
                'type': 'received',
                'message': 'All the Lorem Ipsum generators on the',
                'sentAt': 'Nov 22, 2017, 3:12:45 PM',
            }, {
                'type': 'sent',
                'message': 'There are many variations of passages of ',
                'sentAt': 'Nov 22, 2017, 3:13:04 PM',
            },
        ]
    }

]