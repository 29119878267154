import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TextField, Button } from 'material-ui';
import Radio, { RadioGroup } from 'material-ui/Radio';
import { FormControl, FormControlLabel, FormLabel } from 'material-ui/Form';


import CardBox from 'components/CardBox';
import { SubmitButton, CancelButton } from 'components/Buttons';
import { createResource, updateResource } from 'actions/Resource';

import API from './../../../../helper/api';
import {isEmpty} from './../../../../helper/util';

const api = new API();

class MessageForm extends Component {
  state = {
    title: '',
    detail: '',
    page_type: '',
    page: '',
    url: '',
    image_url: '',
    users: [],
    filterType: 'all-users',
    errors: [],
  };

  componentDidMount() {
    const { messageId } = this.props.match.params;
    if (messageId) {
      api.get(`/messages/${messageId}`)
        .then((response) => {
          console.log(response);
          const {
            title,
            detail,
            pageType: page_type,
            pageValue,
            image_url,
          } = response;

          this.setState({
            title,
            detail,
            page_type,
            image_url,
            page: parseInt(page_type, 10) === 1 ? pageValue : '',
            url: parseInt(page_type, 10) === 2 ? pageValue : '',
          });
        });
    }
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handlePageTypeChange = (e) => {
    const { value } = e.target.value;
    this.handleInputChange(e);

    if (value) {
      if (value == 1) {
        this.setState({ url: '' });
      } else if (value == 2) {
        this.setState({ page: '' });
      }
    } else {
      this.setState({ url: '', page: '' });
    }
  };

  handleFileInputChange = (e, callback) => {
    const { target } = e;
    this.setState(
      () => ({ [target.name]: target.files[0] }),
      () => {
        if (callback) callback();
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { messageId } = this.props.match.params;
    const {
      title,
      detail,
      page_type,
      page,
      url,
      image_url,
      users,
    } = this.state;

    const message = {
      title,
      detail,
      page_type,
      page,
      url,
      image_url,
      users,
    };

    const res = messageId
      ? this.props.updateResource('messages', { id: messageId, ...message })
      : this.props.createResource('messages', message);

    res.then(() => this.setState({ resourceCreated: true })).catch((e) => {

      this.setState({ errors: response.data.errors });
    });
  };

  fetchUsers(filterType) {
    if (filterType === 'all-users') {
      api
        .post(`${process.env.NODE_HOST}/addUsersToMessage`, { all: 'option1' })
        .then(response => this.setState({ users: response }));
    } else if (filterType === 'user-csv') {
      const { userCSV } = this.state;

      if (userCSV) {
        const formData = new FormData();
        formData.append('user-csv', userCSV);

        api
          .post(`${process.env.NODE_HOST}/users/upload`, formData)
          .then((response) => {
            this.input.value = null;
            this.setState({
              userCSV: undefined,
              users: response,
            });
          });
      }
    }
  }

  displayError(errors) {
    return (
      <ul>
        {errors.map((error, i) => (
          <li key={i} className="text-danger">
            {error.msg}
          </li>
        ))}
      </ul>
    );
  }

  displayFilter(filterType) {
    let filter = null;
    if (filterType === 'all-users') {
      filter = (
        <Button
          onClick={() => {
            this.fetchUsers(filterType);
          }}
          raised
          className="jr-btn text-white"
          color="primary"
        >
          Add Users
        </Button>
      );
    } else if (filterType === 'user-csv') {
      filter = (
        <input
          name="userCSV"
          type="file"
          ref={input => (this.input = input)}
          onChange={(e) => {
            this.handleFileInputChange(e, () => this.fetchUsers(filterType));
          }}
        />
      );
    }
    return filter;
  }

  render() {
    const {
      title,
      detail,
      page_type,
      page,
      url,
      image_url,
      users,
      filterType,
      errors,
      resourceCreated,
    } = this.state;


    if (resourceCreated) {
      return <Redirect to={'/app/messages'} />;
    }

    return (
      <div className="row mb-md-4 jr-card">
        <CardBox styleName="col-md-6">
          <div>
            {this.displayError(errors)}
            <form onSubmit={this.handleSubmit}>
              <fieldset>
                <TextField
                  id="title"
                  label="Message Title"
                  name="title"
                  value={title}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="detail"
                  label="Details"
                  name="detail"
                  value={detail}
                  fullWidth
                  multiline
                  rows="4"
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="page_type"
                  select
                  name="page_type"
                  label="Page Type"
                  value={page_type}
                  onChange={this.handlePageTypeChange}
                  SelectProps={{ native: true }}
                  margin="normal"
                  fullWidth
                >
                  <option />
                  <option value="1">Page</option>
                  <option value="2">Url</option>
                </TextField>
                {page_type == 1 && (
                  <TextField
                    id="page"
                    select
                    name="page"
                    label="Page"
                    value={page}
                    onChange={this.handleInputChange}
                    SelectProps={{ native: true }}
                    margin="normal"
                    fullWidth
                  >
                    <option />
                    <option value="bills">Bills</option>
                    <option value="payment_transactions">
                      Payment Transactions
                    </option>
                    <option value="reports">Reports</option>
                    <option value="create_bill">Create Bill</option>
                    <option value="profile">Profile</option>
                    <option value="sms_alert">Sms Alert</option>
                  </TextField>
                )}
                {page_type == 2 && (
                  <TextField
                    id="url"
                    label="URL"
                    name="url"
                    value={url}
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleInputChange}
                  />
                )}
                <div>
                  <label style={{ fontSize: '18' }} className="mr-3">
                    Image
                  </label>
                  <input
                    name="image"
                    type="file"
                    onChange={this.handleFileInputChange}
                  />
                  <TextField
                    id="image_url"
                    label="Image URL"
                    name="image_url"
                    value={!isEmpty(image_url, true) ? image_url : ''}
                    margin="normal"
                    className="col-md-4"
                    onChange={this.handleInputChange}
                  />
                </div>
                <SubmitButton />
                <CancelButton to="/app/messages" />
              </fieldset>
            </form>
          </div>
        </CardBox>
        <CardBox heading="Filter for Users" styleName="col-md-6">
          <div className="col-sm-8">
            <FormControl component="fieldset">
              <RadioGroup
                className="d-flex flex-row"
                aria-label="filterType"
                name="filterType"
                value={filterType}
                onChange={this.handleInputChange}
              >
                <FormControlLabel
                  value="all-users"
                  control={<Radio />}
                  label="Select All Users"
                />
                <FormControlLabel
                  value="user-csv"
                  control={<Radio />}
                  label="Upload Users"
                />
              </RadioGroup>
            </FormControl>
            <div>
              {this.displayFilter(filterType)}
              <p className="mt-4">Total Users: {Object.keys(users).length}</p>
            </div>
          </div>
        </CardBox>
      </div>
    );
  }
}

export default connect(null, { createResource, updateResource })(MessageForm);
