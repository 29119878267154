import { AUTH_USER, UNAUTH_USER, AUTH_ERROR } from 'actions/ActionType';

let user;

try {
  user = JSON.parse(localStorage.getItem('user'));
} catch (e) {
  user = null;
}

const INIT_STATE = user
  ? {
    authenticated: true,
    user,
  }
  : {};

export default (state = INIT_STATE, action) => {
  const { authenticated, errMessage, user } = action;
  switch (action.type) {
    case AUTH_USER:
      localStorage.setItem('user', JSON.stringify(user));
      return { ...state, authenticated, user, errMessage };
    case UNAUTH_USER:
      localStorage.removeItem('user');
      return { ...state, authenticated, user, errMessage };
    case AUTH_ERROR:
      return { ...state, errMessage };
    default:
      return state;
  }
};
