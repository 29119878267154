import { SUCCESS_ALERT, FAILURE_ALERT } from 'actions/ActionType';

export function displaySuccessAlert(message) {
  return {
    type: SUCCESS_ALERT,
    message,
  };
}

export function displayFailureAlert(message) {
  return {
    type: FAILURE_ALERT,
    message,
  };
}
