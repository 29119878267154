import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import ContainerHeader from 'components/ContainerHeader/index';
import UserTable from 'app/routes/users/Components/UserTable';
import UserForm from 'app/routes/users/Components/UserForm';

export default class Users extends Component {
  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader match={match} title="Admin Users" />
          <Switch>
            <Route path={`${match.url}/new`} component={UserForm} />
            <Route path={`${match.url}/:userId`} component={UserForm} />
            <Route path={`${match.url}/`} component={UserTable} />
          </Switch>
        </div>
      </div>
    );
  }
}
