import { format, subDays } from 'date-fns';
import API from './../helper/api';
import { RESET_LOADER } from '../actions/ActionType';
import { makeStartEndDate } from '../helper';

// Stats to get Dashboard details
const api = new API();
export const resetLoader = () => ({
  type: RESET_LOADER
});

export function fetchStats(FETCH_TYPE, route, body = {}) {
  const dates = makeStartEndDate(15);

  const requestBody = {
    ...dates,
    ...body
  };
  return dispatch => api
    .post(`/${route}`, requestBody)
    .then((response) => {
      dispatch({
        type: FETCH_TYPE,
        payload: {...response, timeStamp: +(new Date())}
      });
    })
  ;
}
