import {
  FETCH_AIRTIME_SMS_TRANSACTION,
  FETCH_DATA_SMS_TRANSACTION,
  FETCH_TAGGED_SMS,
  FETCH_UNTAGGED_SMS,
  FETCH_USERS,
  RESET_LOADER,
  FETCH_REVENUE
} from '../actions/ActionType';

const INIT_STATE = {

  users: {},
  taggedSMS: {},
  untaggedSMS: {},
  airtimeSMSTranx: {},
  dataSMSTranx: {},
  revenue: {},
  loader: {
    user: true,
    tagged: true,
    untagged: true,
    airtime: true,
    data: true,
    revenue: true,
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {...state, ...{users: action.payload, loader: {...state.loader, user: false}}};
    case FETCH_TAGGED_SMS:
      return {...state, ...{taggedSMS: action.payload, loader: {...state.loader, tagged: false}}};
    case FETCH_UNTAGGED_SMS:
      return {...state, ...{untaggedSMS: action.payload, loader: {...state.loader, untagged: false}}};
    case FETCH_AIRTIME_SMS_TRANSACTION:
      return {...state, ...{airtimeSMSTranx: action.payload, loader: {...state.loader, airtime: false}}};
    case FETCH_DATA_SMS_TRANSACTION:
      return {...state, ...{dataSMSTranx: action.payload, loader: {...state.loader, data: false}}};
    case FETCH_REVENUE:
      return {...state, ...{revenue: action.payload, loader: {...state.loader, revenue: false}}};
    case RESET_LOADER:
      return {...state, ...{loader: {...INIT_STATE.loader}}};
    default:
      return state;
  }
};
