import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table, { TableBody, TableCell, TableRow } from 'material-ui/Table';

const renderTableBody = stats => (
  <TableBody>
    {stats.map((stat, i) => (
      <TableRow key={i}>
        <TableCell>{stat.stat}</TableCell>
        <TableCell numeric>{stat.value}</TableCell>
      </TableRow>
    ))}
  </TableBody>
);

const StatTable = ({ noWrapTable, stats }) => (noWrapTable ? (
  renderTableBody(stats)
) : (
  <Table>{renderTableBody(stats)}</Table>
));

StatTable.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      stat: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
    }).isRequired
  ).isRequired,
};

export default StatTable;
