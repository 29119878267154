import {
  REQUEST_OPTIONS,
  RECEIVE_OPTIONS,
} from 'actions/ActionType';

const INIT_STATE = {
  options: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_OPTIONS:
      return {
        ...state,
        isFetching: true
      }
    case RECEIVE_OPTIONS:
      return {
        ...state,
        isFetching: false,
        options: action.options
      }
    default:
      return state;
  }
}
