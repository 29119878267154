import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchResource, clearResource } from 'actions/Resource';
import Loader from 'components/Loader';
import UserTableCell from './UserTableCell';

class UserTable extends Component {
  componentDidMount() {
    this.props.fetchResource('adminusers');
  }

  componentWillUnmount() {
    this.props.clearResource();
  }

  render() {

    console.log(this.props.items);
    if (this.props.isFetching) {
      return <Loader />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <div className="jr-card">
            <div className="table-responsive-material">
              <Link
                className="float-right jr-btn bg-primary text-white"
                to="/app/adminusers/new"
                style={{ textDecoration: 'none' }}
              >
                Create User
              </Link>
              <table className="default-table table-unbordered table table-sm table-hover">
                <thead className="th-border-b">
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>User ID</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map((user, index) => <UserTableCell key={`${user.id}-${index}`} user={user} />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ resource }) => ({
  items: resource.items,
  isFetching: resource.isFetching,
});

export default connect(mapStateToProps, { fetchResource, clearResource })(UserTable);
