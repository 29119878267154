import React from 'react';
import PropTypes from 'prop-types';

import StatTable from 'components/StatTable';

const StatCard = ({ header, stats, children }) => {
  return (
    <div className="col-lg-4 col-sm-6 col-12">
      <div className="jr-card">
        <div className="jr-card-header mb-3 d-flex">
          <h2 className="mb-0 mr-auto">{header}</h2>
        </div>
        <StatTable stats={stats} />
        {children}
      </div>
    </div>
  );
};

StatCard.propTypes = {
  header: PropTypes.string.isRequired,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      stat: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
    }).isRequired
  ).isRequired,
};

export default StatCard;
