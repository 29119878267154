import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';
import Menu, { MenuItem } from 'material-ui/Menu';

import { logout } from 'actions/Auth';

class UserInfo extends Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  logout = () => {
    this.handleRequestClose();
    this.props.logout();
  }

  render() {
    const { user } = this.props;
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {/* <Avatar
          alt="..."
          src="http://via.placeholder.com/256x256"
          className="user-avatar "
        /> */}
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {user.firstName}{' '}{user.lastName}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              width: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >

          <MenuItem onClick={this.logout}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />Logout
          </MenuItem>
        </Menu>
      </div>
      
    );
  }
}

const matchStateToProps = ({ auth }) => {
  return {
    user: auth.user
  }
}

export default connect(matchStateToProps, { logout })(UserInfo);
