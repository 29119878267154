import {
  RESET_STATE,
  CLEAR_RESOURCE,
  REQUEST_RESOURCE,
  RECEIVE_RESOURCE_SUCCESS,
  RECEIVE_RESOURCE_FAILURE,
  RECEIVE_CREATE_RESOURCE_SUCCESS,
  RECEIVE_UPDATE_RESOURCE_SUCCESS,
} from 'actions/ActionType';

const INIT_STATE = {
  items: [],
  isFetching: false,
  error: null,
};

function updateResource(state = [], action) {
  return state.map(item => {
    if (item.id == action.item.id) {
      return {
        ...item,
        ...action.item,
      }
    }
    return item
  });
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_RESOURCE:
      return {
        ...state,
        isFetching: true
      }
    case CLEAR_RESOURCE:
      return {
        ...state,
        items: []
      }
    case RESET_STATE:
      return {
        ...state,
        [action.state]: action.defaultValue,
      }
    case RECEIVE_RESOURCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.items
      }
    case RECEIVE_UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        items: updateResource(state.items, action),
      }
    default:
      return state;
  }
}
