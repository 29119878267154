import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TextField, Button, Checkbox } from 'material-ui';
import { FormControlLabel } from 'material-ui/Form';

import CardBox from 'components/CardBox';
import { login } from 'actions/Auth';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      username: '',
      rememberMe: false,
    };
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state);
  };

  render() {
    const { authenticated, errMessage } = this.props;

    if (authenticated) {
      return <Redirect to="/" />;
    }

    return (
      <div className="app-main">
        <div className="app-container fixed-drawer">
          <div className="app-main-container">
            <div className="app-main-content-wrapper">
              <div className="app-main-content">

                <div className="app-wrapper">
                  <div className="animated slideInUpTiny animation-duration-3">
                    <div className="row">
                      <CardBox
                        styleName="col-md-4 col-12 m-auto"
                        heading="Log In"
                      >
                        <div>
                          <p>Enter your login credentials</p>
                          {errMessage &&
                            <p className="text-danger">{errMessage}</p>
                          }
                        </div>
                        <div className="login-form">
                          <form onSubmit={this.handleSubmit}>
                            <input type="hidden" name="_csrf" />
                            <fieldset>
                              <TextField
                                id="username"
                                label="Username"
                                name="username"
                                value={this.state.username}
                                fullWidth
                                margin="normal"
                                className="mt-1"
                                onChange={this.handleInputChange}
                              />
                              <TextField
                                type="password"
                                id="password"
                                label="Password"
                                name="password"
                                value={this.state.password}
                                fullWidth
                                margin="normal"
                                className="mt-1"
                                onChange={this.handleInputChange}
                              />
                              <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="rememberMe"
                                      checked={this.state.rememberMe}
                                      onChange={this.handleInputChange}
                                    />
                                  }
                                  label="Remember me"
                                />
                              </div>

                              <Button
                                raised
                                type="submit"
                                className="jr-btn text-white"
                                color="primary"
                              >
                                Sign In
                              </Button>
                            </fieldset>
                          </form>
                        </div>
                      </CardBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authenticated, errMessage } = auth;
  return {
    authenticated,
    errMessage,
  };
};

export default connect(mapStateToProps, { login })(Login);
