import {
  GET_ALL_TODO,
  GET_IMPORTANT_TODO,
  GET_NAV_FILTERS,
  GET_NAV_LABELS,
  GET_STARRED_TODO,
  GET_UNIMPORTANT_TODO,
  GET_UNSELECTED_ALL_TODO,
  GET_UNSTARRED_TODO,
  HANDLE_REQUEST_CLOSE,
  ON_DELETE_TODO,
  ON_HIDE_LOADER,
  ON_LABEL_MENU_ITEM_SELECT,
  ON_LABEL_SELECT,
  ON_LABEL_UPDATE,
  ON_OPTION_MENU_SELECT,
  ON_SORTEND,
  ON_TODO_ADD,
  ON_TODO_CHECKED,
  ON_TODO_SELECT,
  ON_TODO_UPDATE,
  ON_TOGGLE_DRAWER,
  SEARCH_TODO,
  SELECT_ALL_TODO,
  SET_CURRENT_TODO_NULL,
  UPDATE_SEARCH
} from 'actions/ActionType';


export const onSortEnd = data => ({
  type: ON_SORTEND,
  payload: data
});

export const onOptionMenuSelect = () => ({
  type: ON_OPTION_MENU_SELECT,
});
export const onLabelSelect = () => ({
  type: ON_LABEL_SELECT,
});
export const selectAllTodo = () => ({
  type: SELECT_ALL_TODO,
});
export const getAllTodo = () => ({
  type: GET_ALL_TODO,
});


export const getUnselectedAllTodo = () => ({
  type: GET_UNSELECTED_ALL_TODO,
});

export const getStarredToDo = () => ({
  type: GET_STARRED_TODO,
});

export const getUnStarredTodo = () => ({
  type: GET_UNSTARRED_TODO,
});
export const getImportantToDo = () => ({
  type: GET_IMPORTANT_TODO,
});
export const getUnimportantToDo = () => ({
  type: GET_UNIMPORTANT_TODO,
});
export const onLabelMenuItemSelect = label => ({
  type: ON_LABEL_MENU_ITEM_SELECT,
  payload: label
});
export const onLabelUpdate = data => ({
  type: ON_LABEL_UPDATE,
  payload: data
});

export const onToDoUpdate = data => ({
  type: ON_TODO_UPDATE,
  payload: data
});

export const onDeleteToDo = data => ({
  type: ON_DELETE_TODO,
  payload: data
});
export const getNavFilters = data => ({
  type: GET_NAV_FILTERS,
  payload: data
});
export const getNavLabels = data => ({
  type: GET_NAV_LABELS,
  payload: data
});

export const onSearchTodo = searchText => ({
  type: SEARCH_TODO,
  payload: searchText
});
export const onTodoChecked = data => ({
  type: ON_TODO_CHECKED,
  payload: data
});
export const onTodoAdd = data => ({
  type: ON_TODO_ADD,
  payload: data
});
export const onTodoSelect = todo => ({
  type: ON_TODO_SELECT,
  payload: todo
});
export const setCurrentToDoNull = () => ({
  type: SET_CURRENT_TODO_NULL,
});

export const toDoToggleDrawer = () => ({
  type: ON_TOGGLE_DRAWER,
});

export const updateSearch = searchTodo => ({
  type: UPDATE_SEARCH,
  payload: searchTodo
});

export const hideToDoLoader = () => ({
  type: ON_HIDE_LOADER,
});
export const handleToDoMenuRequestClose = () => ({
  type: HANDLE_REQUEST_CLOSE,
});

