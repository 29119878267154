import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TextField, Button, Checkbox, Chip } from 'material-ui';
import { FormControlLabel } from 'material-ui/Form';

import { SubmitButton, CancelButton } from 'components/Buttons';
import CardBox from 'components/CardBox';
import { createResource, updateResource } from 'actions/Resource';

import API from './../../../../helper/api';
import { isEmpty } from '../../../../helper/util';

const api = new API();
class ServiceForm extends React.Component {
  constructor() {
    super();
    this.state = {
      tag: '',
      tags: [],
      name: '',
      details: '',
      status: false,
      existingTags: [],
    };
  }

  componentDidMount() {
    const { serviceId } = this.props.match.params;
    if (serviceId) {
      api.get(`/services/${serviceId}`).then((response) => {
        const {
          name,
          details,
          tags: existingTags,
          is_active: status,
        } = response;

        this.setState({
          name,
          status,
          details,
          existingTags,
        });
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, details, tags, status } = this.state;
    const { serviceId } = this.props.match.params;
    const service = {
      name,
      details,
      tags,
      status,
      countryid: 1,
    };

    if (serviceId) {
      this.props.updateResource(
        'services',
        { id: serviceId, ...service },
        this.redirect
      );
    } else {
      this.props.createResource('services', service, this.redirect);
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleCheckBoxChange = name => (event) => {

    this.setState({ ...this.state, [name]: event.target.checked });
  };


  handleDelete = (i) => {
    this.setState({
      tags: [...this.state.tags.slice(0, i), ...this.state.tags.slice(i + 1)],
    });
  };

  removeTagFromService = (i) => {
    const { existingTags } = this.state;

    api
      .get(
        `/api/removeTagFromService?tagId=${
          existingTags[i].id
        }&serviceId=${this.props.match.params.serviceId}`
      )
      .then((response) => {
        this.setState({
          existingTags: [
            ...this.state.existingTags.slice(0, i),
            ...this.state.existingTags.slice(i + 1),
          ],
        });
      });
  };

  addTagToList = (e) => {
    if (e.which === 13 || e.which === 188) {
      e.preventDefault();
      const { tag, tags } = this.state;
      if (!isEmpty(tag, true)) {
        this.setState({
          tags: [...new Set(tags.concat(tag.toLowerCase()))],
          tag: '',
        });
      }
    }
  };

  redirect = () => {
    this.setState({ resourceCreated: true });
  };

  render() {
    const {
      name,
      details,
      tags,
      existingTags,
      status,
      tag,
      resourceCreated,
    } = this.state;


    if (resourceCreated) {
      return <Redirect to={'/app/services'} />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <CardBox styleName="col-md-6 m-auto">
            <form onSubmit={this.handleSubmit}>
              <fieldset>
                <TextField
                  id="name"
                  label="Name"
                  name="name"
                  value={name}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="details"
                  label="Description"
                  name="details"
                  value={details}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="tag"
                  label="Enter comma separated tags here"
                  name="tag"
                  value={tag}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                  onKeyDown={this.addTagToList}
                />
                <div className="manage-margin d-flex flex-wrap">
                  {existingTags.map((tag, i) => (
                    <Chip
                      label={tag.name}
                      key={i}
                      onDelete={this.removeTagFromService.bind(null, i)}
                    />
                  ))}
                  {tags.map((tag, i) => (
                    <Chip
                      label={tag}
                      key={i + existingTags.length}
                      onDelete={this.handleDelete.bind(null, i)}
                    />
                  ))}
                </div>
                <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="status"
                        checked={typeof status === 'boolean' ? status : status === 1}
                        onChange={this.handleCheckBoxChange('status')}
                      />
                    }
                    label="Active"
                  />
                </div>
                <SubmitButton />
                <CancelButton to="/app/services" />
              </fieldset>
            </form>
          </CardBox>
        </div>
      </div>
    );
  }
}

export default connect(null, { createResource, updateResource })(ServiceForm);
