import API from './../helper/api';
import { AUTH_USER, UNAUTH_USER, AUTH_ERROR } from './ActionType';

const api = new API();

function receiveAuthUser(type, { authenticated, user, errMessage }) {
  return { type, authenticated, user, errMessage };
}

function receiveAuthError(errMessage) {
  return {
    type: AUTH_ERROR,
    errMessage:
      errMessage === 'Request failed with status code 401'
        ? 'Incorrect username or password'
        : 'Something went wrong. Please try again.',
  };
}

export function login({ username, password, rememberMe }) {
  return dispatch =>
    api
      .post('/login', { username, password, rememberMe })

      .then((response) => {
        dispatch(receiveAuthUser(AUTH_USER, response));
      })
      .catch(err => dispatch(receiveAuthError(err.message)));
}

export function logout() {
  return dispatch =>
    api
      .post('/logout')
      .then((response) => {
        dispatch(receiveAuthUser(UNAUTH_USER, response));
      });
}
