import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TextField, Button, MenuItem, Select } from 'material-ui';
import { FormControlLabel } from 'material-ui/Form';
import Chip from 'material-ui/Chip';

import CardBox from 'components/CardBox';
import { SubmitButton, CancelButton } from 'components/Buttons';
import { createResource } from 'actions/Resource';
import TagTable from './TagTable';

import API from './../../../../helper/api';

const api = new API();

class TagForm extends React.Component {
  constructor() {
    super();
    this.state = {
      tag: '',
      parent: '',
      parent_id: '',
      tags: [],
      tag_list: [],
      errMessage: '',
      resourceCreated: false,
    };
    this.handleParentChange = this.handleParentChange.bind(this);
  }

  componentDidMount() {
    api.get('/tags').then((res) => {
      this.setState({
        tag_list: res.records,
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { tags } = this.state;
    tags[tags.length] = this.state.parent_id;
    if (tags.length) {
      this.props.createResource('tags', tags, this.redirect);
    } else {
      this.setState({ errMessage: 'Please enter tags.' });
    }
  };

  handleInputChange = (e) => {
    this.setState({ tag: e.target.value });
  };

  handleDelete = (index) => {
    const tags = [
      ...this.state.tags.slice(0, index),
      ...this.state.tags.slice(index + 1),
    ];
    this.setState({ tags });
  };

  addTagToList = (e) => {
    if (e.which === 13 || e.which === 188) {
      e.preventDefault();
      const { tag, tags } = this.state;
      if (tag !== '' && tag !== undefined && tag !== null) {
        this.setState({
          tags: [...new Set(tags.concat(tag.toLowerCase()))],
          tag: '',
        });
      }
    }
  };

  handleParentChange(e) {
    const val = e.target.value;
    console.log(val);
    const parts = val.split(',');
    console.log(parts);
    this.setState({
      parent: parts[0],
      parent_id: parts[1],
    });
  }

  redirect = () => {
    this.setState({ resourceCreated: true });
  };

  render() {
    const {
      errMessage,
      name,
      parent,
      tags,
      tag,
      tag_list,
      resourceCreated,
    } = this.state;
    if (resourceCreated) {
      return <Redirect to={'/app/tags'} />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <CardBox styleName="col-md-6 m-auto">
            <div>
              {errMessage && <p className="text-danger">{errMessage}</p>}
              <form onSubmit={this.handleSubmit}>
                <fieldset>
                  <TextField
                    id="tags"
                    label="Enter comma separated tags here"
                    name="tags"
                    value={tag}
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleInputChange}
                    onKeyDown={this.addTagToList}
                  />
                  <Select
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    value={parent}
                    onChange={this.handleParentChange}
                  >
                    {tag_list.map((val, index) => (
                      <MenuItem key={index} value={`${val.tag_name},${val.id}`}>
                        {val.tag_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <label>Parent : {parent}</label>
                  <SubmitButton />
                  <CancelButton to="/app/tags" />
                </fieldset>
              </form>
              <div className="manage-margin d-flex flex-wrap">
                {tags.map((tag, i) => (
                  <Chip
                    label={tag}
                    key={i}
                    onDelete={this.handleDelete.bind(null, i)}
                  />
                ))}
              </div>
            </div>
          </CardBox>
        </div>
      </div>
    );
  }
}

export default connect(null, { createResource })(TagForm);
