/* eslint-disable jsx-a11y/href-no-hash */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ContainerHeader from 'components/ContainerHeader';
import TagTable from 'app/routes/unknownSmsAlerts/Components/Table';
import TagForm from 'app/routes/unknownSmsAlerts/Components/Form';
import TagEditForm from 'app/routes/unknownSmsAlerts/Components/EditForm';

export default class UnknownSmsAlerts extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            style={{ marginBottom: '0px' }}
            match={this.props.match}
            title="Unknown Sms Alerts"
          />
          <Switch>
            <Route path={`${match.url}/new`} component={TagForm} />
            <Route path={`${match.url}/:tagId`} component={TagEditForm} />
            <Route path={`${match.url}/`} component={TagTable} />
          </Switch>
        </div>
      </div>
    );
  }
}
