import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchResource, clearResource } from 'actions/Resource';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';

import OptionTableCell from './OptionTableCell';

class OptionTable extends PureComponent {
  state = {
    page: 1,
    totalEntries: 0,
  }

  componentDidMount() {
    const { location, fetchResource } = this.props;
    const query = new URLSearchParams(location.search);
    fetchResource(`options?${query.toString()}`).then((response) => {
      this.setState({
        page: parseInt(query.get('page')) || 1,
        totalEntries: response.totalEntries,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { location, fetchResource } = this.props;
    const { location: nextLocation } = nextProps;
    const query = new URLSearchParams(location.search);
    const nextQuery = new URLSearchParams(nextLocation.search);

    if (query.get('page') !== nextQuery.get('page'))
    { 
fetchResource(`options?${nextQuery.toString()}`).then((response) => {
      this.setState({
        page: parseInt(nextQuery.get('page')),
        location: nextLocation,
      });
    }); 
}
  }

  componentWillUnmount() {
    this.props.clearResource();
  }

  render() {
    if (this.props.isFetching) {
      return <Loader />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <div className="jr-card">
            <div className="table-responsive-material">
              <Link
                className="float-right jr-btn bg-primary text-white"
                to="/app/options/new"
                style={{ textDecoration: 'none' }}
              >
                Create Option
              </Link>
              <table className="default-table table-unbordered table table-sm table-hover">
                <thead className="th-border-b">
                  <tr>
                    <th>Name</th>
                    <th>Details</th>
                    <th>Amount</th>
                    <th>Commission</th>
                    <th>Commission Type</th>
                    <th>Payment Option</th>
                    <th>Service Name</th>
                    <th className="status-cell text-right">Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map(option => <OptionTableCell key={option.id} option={option} />)}
                </tbody>
              </table>
            </div>
            <div className="mt-5 float-right">
              <Pagination
                location={this.props.location}
                currentPage={this.state.page}
                totalEntries={this.state.totalEntries}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ routing, resource }) => ({
  location: routing.location,
  items: resource.items,
  isFetching: resource.isFetching,
});

export default connect(mapStateToProps, { fetchResource, clearResource })(OptionTable);
