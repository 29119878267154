/* eslint-disable no-nested-ternary */
/* eslint-disable semi */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/href-no-hash */
/* eslint-disable radix */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchResource, updateResource, clearResource } from 'actions/Resource';
import Loader from 'components/Loader';
import Card from 'components/Cards/Sample';
import Pagination from 'components/Pagination';
import MIPagination from 'components/MaterialPagination';
import ReportBox from 'components/ReportBox/index';
import LinearProgress from '@material-ui/core/LinearProgress';
import CSVDownload from './CSVDownload';
import { getLocalStorage } from "../../../../helper/storage";

import StatTable from 'components/StatTable';
import Table, {
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'material-ui/Table';
import Button from 'material-ui/Button';
import Select from 'material-ui/Select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


import TagTableCell from './TableCell';
import StatCard from '../../statDashboard/Components/StatCard';

import API from '../../../../helper/api';

const api = new API();


const headCells = [
  {
    id: 'sms_sender',
    numeric: false,
    disablePadding: false,
    label: 'SMS Sender',
  },
  { id: 'sms_body', numeric: false, disablePadding: false, label: 'SMS Body' },
  { id: 'date_received', numeric: false, disablePadding: false, label: 'Date' },
];

class TagTable extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      action: '',
      modal: false,
      loader: true,
      ids: [],
      type: 'shown',
      process: false,
      bank: 'All banks',
      records: [],
      stock: [],
      totalHidden: 0,
      totalShown: 0,
      totalSmsByBank: [
        {
          stat: 'banks',
          value: 0,
        },
      ],
      page: 1,
      totalEntries: 0,
      isButtonDisabled: false,
      buttonText: 'Process SMS',
      progress: 9,
      showloader: false,
      currentUserEmail: getLocalStorage("user").email
    };
    
    this.processUnknownSMSWithConfig = this.processUnknownSMSWithConfig.bind(
      this,
    );
    this.getSMS = this.getSMS.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.addMarkedSms = this.addMarkedSms.bind(this);
    this.markAllSms = this.markAllSms.bind(this);
    this.toggleUnknownSMS = this.toggleUnknownSMS.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.cancelRequest = this.cancelRequest.bind(this);
    this.progressTimeOut = this.progressTimeOut.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      const { location, fetchResource } = this.props;
      const query = new URLSearchParams(location.search);
      const { page, bank, type } = this.state;
      this.getSMS(page, type, bank);
    }
    
  }

  componentWillReceiveProps(nextProps) {
    const { location, fetchResource } = this.props;
    const { location: nextLocation } = nextProps;
    const query = new URLSearchParams(location.search);
    const nextQuery = new URLSearchParams(nextLocation.search);
    this.setState({
      loader: true,
    });
    const { page, type, bank } = this.state;
    api
      .get(`/api/sms_alerts/unknown?page=${page}&type=${type}&bank=${bank}`)
      .then((res) => {
        let response = { ...res };
        if (response.totalEntries > 0) {
          this.setState({
            totalHidden: response.totalHidden,
            totalShown: response.totalShown,
            records: response.records,
            stock: response.records,
            page: parseInt(response.page),
            totalEntries: response.totalEntries,
            totalSmsByBank: response.totalSmsByBank,
            process: false,
            loader: false,
          });
        } else {
          this.setState({
            records: [],
            stock: [],
            totalHidden: 0,
            totalShown: 0,
            totalEntries: 0,
            totalSmsByBank: [
              {
                stat: 'banks',
                value: 0,
              },
            ],
            process: false,
            loader: false,
          });
        }
      });
  }
  processUnknownSMSWithConfig() {
    this.setState({
      process: true,
      isButtonDisabled: true,
      buttonText: 'Processing SMS....',
      showloader: true,
    });

    api
      .get('/api/process_unknown_sms')
      .then((res) => {
        if (res.success) {
          this.setState({
            isButtonDisabled: false,
            buttonText: 'Process SMS',
          });
          const { page, bank, type } = this.state;
          this.getSMS(page, type, bank);
          this.progressTimeOut();
        }
      })
      .catch((err) => {
        this.setState({
          isButtonDisabled: false,
          buttonText: 'Process SMS',
        });
        this.progressTimeOut();
        console.log(err);
      });
  }

  progressTimeOut() {
    setTimeout(() => {
      this.setState({ showloader: false, progress: 9 });
    }, 500);
  }

  cancelRequest() {
    cancel();
    this.setState({
      isButtonDisabled: false,
      buttonText: 'Process SMS',
      showloader: false,
      progress: 9,
    });
    const { page, bank, type } = this.state;
    this.getSMS(page, type, bank);
  }
  toggleUnknownSMS(type) {
    this.setState({ process: true, modal: false });
    api
      .post(`/api/${type}_unknown_sms/`, {
        data: this.state.ids,
      })
      .then((res) => {
        this.setState({
          ids: [],
          bank: 'All banks',
        });
        const { page, bank, type } = this.state;
        this.getSMS(page, type, 'All banks');
      });
  }

  changeDisplay(display) {
    this.setState({
      type: display,
      bank: 'All banks',
    });
    const { page, bank, type } = this.state;
    this.getSMS(page, display, 'All banks');
  }

  addMarkedSms(id) {
    var newSet = this.state.ids;
    if (newSet.find(val => val == id)) {
      this.removeMarkedSms(id);
    } else {
      newSet.push(id);
      this.setState({
        ids: newSet,
      });
    }
  }
  markAllSms(id) {
    var newSet = this.state.ids;
    var ids = this.state.stock.map(x => x.id);

    if (newSet.length > 0) {
      this.setState({
        ids: [],
      });
    } else {
      this.setState({
        ids,
      });
    }
  }
  removeMarkedSms(id) {
    var newSet = this.state.ids.filter((val) => {
      if (val != id) return val;
    });

    this.setState({
      ids: newSet,
    });
  }
  getSMS(page, type, bank) {
    this.setState({
      loader: true,
    });
    this.setState({ bank });
    api
      .get(`/api/sms_alerts/unknown?page=${page}&type=${type}&bank=${bank}`)
      .then((res) => {
        let response = { ...res };
        if (response.totalEntries > 0) {
          this.setState({
            totalHidden: response.totalHidden,
            totalShown: response.totalShown,
            records: response.records,
            stock: response.records,
            page: parseInt(response.page),
            totalEntries: response.totalEntries,
            totalSmsByBank: response.totalSmsByBank,
            process: false,
            loader: false,
          });
        } else {
          this.setState({
            records: [],
            stock: [],
            totalHidden: 0,
            totalShown: 0,
            totalEntries: 0,
            totalSmsByBank: [
              {
                stat: 'banks',
                value: 0,
              },
            ],
            process: false,
            loader: false,
          });
        }
      });
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeOut());
    this.props.clearResource();
    this._isMounted = false;
  }

  toggleModal(type) {
    switch (type) {
      case 'close':
        this.setState({
          modal: false,
          action: '',
        });
        break;
      case 'hide':
        this.setState({
          modal: true,
          action: 'HIDE',
        });
        break;
      case 'delete':
        this.setState({
          modal: true,
          action: 'DELETE',
        });
        break;
      case 'show':
        this.setState({
          modal: true,
          action: 'SHOW',
        });
        break;

      default:
        this.setState({
          modal: false,
          action: '',
        });
        break;
    }
  }

  onHandleGetObjID = (objIDs) => {
    this.setState({ ids: objIDs });
  };

  showDownloadCSVBtn = () => {
    const { records, currentUserEmail, bank } = this.state;
    if(records.length > 0 && currentUserEmail === "viewer@kliqr.com") {
      return (
        <Button
          className="jr-btn bg-primary text-white"
          style={{ textDecoration: 'none', marginLeft:50 }}
        >
        <CSVDownload data={records} bank={bank}/>
        </Button>
      )
    }
    return null;
  }

  render() {
    return (
      <div>
        {this.state.showloader ? (
          <div>
            <LinearProgress variant="determinate" value={this.state.progress} />
            <br />
          </div>
        ) : null}

        <div className="jr-card">
          <Select
            style={{ direction: 'rtl' }}
            native
            value={this.state.bank}
            onChange={(e) => {
              const value = e.target.value;
              this.setState({ bank: value, ids: [] });
              const { page, bank, type } = this.state;
              this.getSMS(page, type, value);
            }}
          >
            <option value="All banks">
              All Banks {this.state.totalEntries}
            </option>
            {this.state.totalSmsByBank.map((items, index) => (
              <option key={index} value={items.stat}>
                {items.stat} {items.value}
              </option>
            ))}
          </Select>

          {this.process ? (
            <Loader />
          ) : this.state.isButtonDisabled ? (
            <Button
              className="float-right jr-btn bg-primary text-white"
              onClick={this.cancelRequest}
              style={{ textDecoration: 'none' }}
            >
              Stop Processing Request
            </Button>
          ) : (
            <Button
              className="float-right jr-btn bg-primary text-white"
              onClick={this.processUnknownSMSWithConfig}
              style={{ textDecoration: 'none' }}
            >
              {this.state.buttonText ? this.state.buttonText : '&nbsp;'}
            </Button>
          )}
          <Select
            className="float-right"
            style={{ marginRight: '10px' }}
            native
            value={'shown'}
            onChange={e => this.toggleModal(e.target.value)}
          >
            <option>Select Action</option>
            <option value="delete">Delete Marked SMS</option>

            {this.state.type === 'shown' ? (
              <option value="hide">Hide Marked SMS</option>
            ) : (
              <option value="show">Show Marked SMS</option>
            )}
          </Select>
          <span style={{ marginLeft: '20px' }}>
            <input
              style={{ marginRight: '5px' }}
              type="checkbox"
              checked={this.props.checkBoxDefaultStatus}
              onChange={(e) => {
                const value = e.target.checked ? 'hidden' : 'shown';
                this.setState({
                  type: value,
                });
                this.changeDisplay(value);
              }}
            />
            Display Hidden SMS
          </span>

          {this.showDownloadCSVBtn() /*only for viewer@kliqr.com*/}

          {/* <DatePicker
className="module-date"
            value={'today'}
            invalidLabel="Due Date"
            onChange={this.handleDueDateChange.bind(this)}
            animateYearScrolling={false}
            leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
            rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <i className="zmdi zmdi-calendar" />
                </IconButton>
              ),
            }}
          /> */}
        </div>

        {this.state.loader ? (
          <Loader />
        ) : (
          <div className="row mb-md-4">
            <div className="col-12">
              <div>
                <MIPagination
                  tableHead={headCells}
                  data={this.state.records}
                  getObjIDs={this.onHandleGetObjID}
                  isLoading={this.state.loader}
                />
              </div>
            </div>
          </div>
        )}

        <div>
          <Button color="contrast" onClick={this.toggleModal}>
            {this.props.buttonLabel ? this.props.buttonLabel : '&nbsp;'}
          </Button>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggleModal}
            className={this.props.className}
          >
            <ModalBody>
              Are you sure you want to {this.state.action} these messages
            </ModalBody>
            <ModalFooter>
              <Button
                className="bg-warning text-white"
                onClick={() => this.toggleUnknownSMS(this.state.action)}
              >
                {this.state.action ? this.state.action : '&nbsp;'}
              </Button>{' '}
              <Button
                className="bg-danger text-white"
                onClick={() => this.toggleModal('close')}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ resource }) => ({
  items: resource.items,
  isFetching: resource.isFetching,
});

export default connect(mapStateToProps, {
  fetchResource,
  updateResource,
  clearResource,
})(TagTable);
