import React, { PureComponent } from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';

export default class ServiceTableCell extends PureComponent {
  state = {
    anchorEl: undefined,
    open: false,
  };

  openMenu = event => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  render() {
    const { service } = this.props;
    let statusColor, statusText, actionText;

    if (service.is_active) {
      statusColor = 'success';
      statusText = 'Active';
      actionText = 'Deactivate';
    } else {
      statusColor = 'danger';
      statusText = 'Inactive';
      actionText = 'Activate';
    }

    return (
      <tr>
        <td>{service.name}</td>
        <td>{service.details}</td>
        <td className="status-cell text-right">
          <Badge color={statusColor}>{statusText}</Badge>
        </td>
        <td className="text-right">
          <IconButton className="size-30" onClick={this.openMenu}>
            <i className="zmdi zmdi-more-vert" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.closeMenu}
            style={{ maxHeight: 40 * 4.5 }}
            MenuListProps={{ style: { width: 150 } }}
          >
            <Link
              to={`/app/services/${service.id}`}
              style={{ textDecoration: 'none' }}
            >
              <MenuItem>Edit</MenuItem>
            </Link>
            <Link
              to={`/app/options?id=${service.id}`}
              style={{ textDecoration: 'none' }}
            >
              <MenuItem data-service-id={service.id} onClick={this.closeMenu}>
                Options
              </MenuItem>
            </Link>
          </Menu>
        </td>
      </tr>
    );
  }
}
