import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Progress from './routes/progress';
import NewConfig from './routes/newConfig';

const Config = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/progress`} />
    
      <Route path={`${match.url}/progress`} component={Progress} />
    </Switch>
  </div>
);

export default Config;
