import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchResource, clearResource } from 'actions/Resource';
import { displaySuccessAlert, displayFailureAlert } from 'actions/Alert';
import Loader from 'components/Loader';


import MessageTableCell from './MessageTableCell';

import API from './../../../../helper/api';

const api = new API();

class MessageTable extends Component {
  componentDidMount() {
    this.props.fetchResource('messages');
  }

  componentWillUnmount() {
    this.props.clearResource();
  }

  sendMessage = (e) => {
    const { id } = e.target.dataset;
    api
      .get(`/messages?msg_id=${id}`)
      .then(response => this.props.displaySuccessAlert(response.data))
      .catch((e) => {
        this.props.displayFailureAlert(e.response.data);
      });
  };

  render() {
    if (this.props.isFetching) {
      return <Loader />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <div className="jr-card">
            <div className="table-responsive-material">
              <Link
                className="float-right jr-btn bg-primary text-white"
                to="/app/messages/new"
                style={{ textDecoration: 'none' }}
              >
                Create Message
              </Link>
              <table className="default-table table-unbordered table table-sm table-hover">
                <thead className="th-border-b">
                  <tr>
                    <th>Title</th>
                    <th>Date Created</th>
                    <th>Date Sent</th>
                    <th>Number of Users</th>
                    <th className="status-cell text-right">Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map(message => (
                    <MessageTableCell
                      key={message.id}
                      message={message}
                      onSendMessage={this.sendMessage}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ resource }) => ({
  items: resource.items,
  isFetching: resource.isFetching,
});

export default connect(mapStateToProps, {
  fetchResource,
  clearResource,
  displayFailureAlert,
  displaySuccessAlert,
})(MessageTable);
