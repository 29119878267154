import React from 'react';
import {ResponsiveContainer} from 'recharts';

const ReportBox = ({styleName, heading, title, detail, children, subHeadingColor}) => {
  const updateStyleName = !styleName ? '' : styleName;
  const updateSubHeadingColor = !subHeadingColor ? '' : subHeadingColor;
  return (
    <div className={`jr-card ${updateStyleName}`}>
      {heading && <div className="jr-card-header">
        <h3 className="mb-0">{heading}</h3>
      </div>}
      <div className="row align-items-center">
        <div className="col-5">
          <h1 className="chart-f30 font-weight-light mb-1">{title}</h1>
          <span className={`sub-heading ${updateSubHeadingColor}`}>{detail}</span>
        </div>
        <div className="col-7">
          <ResponsiveContainer height={95} width="99%">
            {children}
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ReportBox;
