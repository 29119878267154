import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';
import 'jquery-slimscroll/jquery.slimscroll.min';

import { toggleCollapsedNav } from 'actions/index';

import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import StatDashboard from './routes/statDashboard';
import Tags from './routes/tags';
import Services from './routes/services';
import Options from './routes/options';
import Messages from './routes/messages';
import Users from './routes/users';
import Config from './routes/config';
import UnknownSmsAlerts from './routes/unknownSmsAlerts';


class App extends React.Component {
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  render() {
    const { match, drawerType, shouldDisplayAlert, message, color } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
        ? 'collapsible-drawer'
        : 'mini-drawer';

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar onToggleCollapsedNav={this.onToggleCollapsedNav.bind(this)} />
        <div className="app-main-container">
          <Header
            drawerType={drawerType}
            onToggleCollapsedNav={this.onToggleCollapsedNav}
          />
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              {shouldDisplayAlert && <div className="app-wrapper" style={{ paddingBottom: 0 }}>
                <UncontrolledAlert className="shadow-lg" color={color} style={{ marginBottom: 0 }}>
                  {message}
                </UncontrolledAlert>
              </div>}
              <Route path={`${match.url}/admin-dashboard`} component={StatDashboard} />
              <Route path={`${match.url}/tags`} component={Tags} />
              <Route path={`${match.url}/services`} component={Services} />
              <Route path={`${match.url}/options`} component={Options} />
              <Route path={`${match.url}/messages`} component={Messages} />
              <Route path={`${match.url}/adminusers`} component={Users} />
              <Route path={`${match.url}/config`} component={Config} />
              <Route path={`${match.url}/unknown-sms-alerts`} component={UnknownSmsAlerts} />
            </div>
          </main>
          <Footer />
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ alert, settings }) => {
  const { shouldDisplayAlert, message, color } = alert;
  const { navCollapsed, drawerType } = settings;

  return { shouldDisplayAlert, message, color, navCollapsed, drawerType };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav })(App)
);
