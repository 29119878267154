import { isEmpty, getDeepObjValue } from './util';
import { getLocalStorage } from './storage';

export default class API {
  constructor(options = {}) {

    this.headers = {
      ...{
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options
    };
  }

  get = route => new Promise(async (resolve, reject) => {
    try {
      const response = await this.xhr(route, null, 'GET');
      return resolve(response);
    } catch (e) {
      console.log(e);
      return reject('');
    }
  })

  put = (route, params) => new Promise(async (resolve, reject) => {
    try {
      const data = isEmpty(params) ? {} : {...params};
      const response = await this.xhr(route, data, 'PUT');
      return resolve(response);
    } catch (e) {
      console.log(e);
      return reject('');
    }
  });

  post = async (route, params) => new Promise(async (resolve, reject) => {
    try {
      const data = isEmpty(params) ? {} : {...params};
      const response = await this.xhr(route, data, 'POST');
      return resolve(response);
    } catch (e) {
      console.log(e);
      return reject('');
    }
  });

  remove = async (route, params) => new Promise(async (resolve, reject) => {
    try {
      const data = isEmpty(params) ? {} : {...params};
      const response = this.xhr(route, data, 'DELETE');
      return resolve(response);
    } catch (e) {
      console.log(e);
      return reject('');
    }
  });

  xhr = async (route, params, verb) => {
    const auth = getLocalStorage('user');
    if (isEmpty(auth) && window.location.pathname !== '/login') {
      const appDomain = window.location.origin;
      window.location.replace(`${appDomain}/login`);
      return false;
    }
    let newParams;
    if (verb === 'GET') {
      newParams = null;
    } else {
      newParams = !isEmpty(getDeepObjValue(auth, 'api_key')) ? {...params, ...{api_key: auth.api_key}} : {...params};
    }

    const apiDomain = process.env.NODE_HOST;
    const host = `${apiDomain}`;
    const url = route.indexOf('://') > -1 ? route : `${host}${route}`;

    const options = Object.assign({ method: verb }, newParams ? { body: JSON.stringify(newParams) } : null);

    options.headers = {...this.headers, ...{'get-auth-user': `Venten ${getDeepObjValue(auth, 'token')}`}};


    try {
      const response = await fetch(url, options);
      const result = await response.json();
      const payload = {...result, ...{timestamp: +(new Date())}};
      return payload;

    } catch (e) {
      console.log(e);
      return '';
    }
  };
}

