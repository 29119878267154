import React from 'react';
import { connect } from 'react-redux';

import ContainerHeader from 'components/ContainerHeader/index';
import ProgressTable from './Components/ProgressTable';

const Progress = ({ match }) => (
  <div className="dashboard animated slideInUpTiny animation-duration-3">
    <ContainerHeader match={match} title="Config Process" />
    <ProgressTable />
  </div>
);

export default Progress;
