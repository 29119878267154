import React, { Component } from 'react'
import { CSVLink } from "react-csv";


class CSVDownload extends Component {
  constructor(props) {
    super(props);
    const {data, bank} = this.props;
    this.state = {data, bank}
  }

  componentDidUpdate(prevProps) {
    const { data, bank } = this.props;
    if (prevProps.data !== data) {
        this.setState({data, bank});
    } 
  }

  render() {
    const { data, bank } = this.state;
    const headers = [
      { label: "SMS Sender", key: "sms_sender" },
      { label: "SMS Body", key: "sms_body" },
      { label: "Date", key: "date_received" }
    ];

    return (
      <CSVLink data={data} headers={headers} filename={`${bank}_${data.length}.csv`} style={{textDecoration: "none"}}>
        <span style={{color: "#fff"}}>Download</span>
      </CSVLink>
    )
  }
}

export default CSVDownload;