export default [
    {
        id: 1,
        name: 'Alex Dolgove',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'away',
        mood: 'English versions from the 1914 translation by H. Rackham',
        lastMessage: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
        unreadMessage: '',
        lastMessageTime: '20/11/17',
        recent: false
    }, {
        id: 2,
        name: 'Domnic Brown',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'online',
        mood: 'English versions from the 1914 translation by H. Rackham',
        lastMessage: 'It is a long established fact',
        unreadMessage: '4',
        lastMessageTime: '20/11/17',
        recent: true
    }, {
        id: 3,
        name: 'Domnic Harris',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'offline',
        mood: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
        lastMessage: 'There are many variations of passages of ',
        unreadMessage: '',
        lastMessageTime: '20/11/17',
        recent: false
    }, {
        id: 4,
        name: 'Garry Sobars',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'away',
        mood: 'It is a long established fact',
        lastMessage: 'English versions from the 1914 translation by H. Rackham',
        unreadMessage: '3',
        lastMessageTime: '21/11/17',
        recent: true
    }, {
        id: 5,
        name: 'Jeson Born',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'away',
        mood: 'I must explain to you how all this mistaken idea of denouncing ',
        lastMessage: 'It is a long established fact',
        unreadMessage: '',
        lastMessageTime: '21/11/17',
        recent: true
    }, {
        id: 6,
        name: 'Jimmy Jo',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'online',
        mood: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
        lastMessage: 'All the Lorem Ipsum generators on the',
        unreadMessage: '',
        lastMessageTime: '22/11/17',
        recent: false
    }, {
        id: 7,
        name: 'John Smith',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'away',
        mood: 'There are many variations of passages of ',
        lastMessage: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
        unreadMessage: '',
        lastMessageTime: '22/11/17',
        recent: true
    }, {
        id: 8,
        name: 'Kadir M',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'online',
        mood: 'All the Lorem Ipsum generators on the',
        lastMessage: 'I must explain to you how all this mistaken idea of denouncing ',
        unreadMessage: '5',
        lastMessageTime: '22/11/17',
        recent: false
    }, {
        id: 9,
        name: 'Jimmy Jon',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'offline',
        mood: 'There are many variations of passages of ',
        lastMessage: 'There are many variations of passages of ',
        unreadMessage: '',
        lastMessageTime: '23/11/17',
        recent: false
    }, {
        id: 10,
        name: 'Stella Johnson',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'offline',
        mood: 'It is a long established fact',
        lastMessage: 'English versions from the 1914 translation by H. Rackham',
        unreadMessage: '',
        lastMessageTime: '23/11/17',
        recent: false
    }, {
        id: 11,
        name: 'Steve Smith',
        thumb: 'http://via.placeholder.com/256x256',
        status: 'online',
        mood: 'The standard chunk of Lorem Ipsum used since the 1500s',
        lastMessage: 'The standard chunk of Lorem Ipsum used since the 1500s',
        unreadMessage: '2',
        lastMessageTime: '23/11/17',
        recent: false
    }
]