import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';

export default class UserTableCell extends PureComponent {
  state = {
    anchorEl: undefined,
    open: false,
  };

  openMenu = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  render() {
    const { user } = this.props;
    console.log(user);
    return (
      <tr>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.email}</td>
        <td>{user.mobilePhone}</td>
        <td>{user.id}</td>
        <td className="text-right">
          <IconButton className="size-30" onClick={this.openMenu}>
            <i className="zmdi zmdi-more-vert" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.closeMenu}
            style={{ maxHeight: 40 * 4.5 }}
            MenuListProps={{ style: { width: 230 } }}
          >
            <Link
              style={{ textDecoration: 'none' }}
              to={`/app/adminusers/${user.id}`}
            >
              <MenuItem onClick={this.closeMenu}>Edit</MenuItem>
            </Link>
            <MenuItem onClick={this.closeMenu}>View Bills</MenuItem>
            <MenuItem onClick={this.closeMenu}>View Bill Payment</MenuItem>
            <MenuItem onClick={this.closeMenu}>View Bank Transactions</MenuItem>
            <MenuItem onClick={this.closeMenu}>View Payment Transactions</MenuItem>
          </Menu>
        </td>
      </tr>
    );
  }
}
