import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';

function renderPageLinks(props) {
  const {
    currentPage,
    location: { search, pathname },
  } = props;
  const pages = pagination(props);

  return pages.map((page, index) => {
    const urlQuery = new URLSearchParams(search);
    urlQuery.set('page', page);
    return (
      <PaginationItem active={currentPage == page} key={index}>
        <Link to={`${pathname}?${urlQuery.toString()}`} className="page-link">
          {page}
        </Link>
      </PaginationItem>
    );
  });
}

function pagination({ currentPage, totalEntries, perPage, offset }) {
  const pageNumbers = [],
    totalPages = Math.ceil(totalEntries / perPage);
  let start, 
end;

  if (totalPages >= offset * 2 + 1) {
    const lowestMidPoint = offset + 1;
    const highestMidPoint = totalPages - offset;
    let midPoint = currentPage;
    if (currentPage < lowestMidPoint) {
      midPoint = lowestMidPoint;
    } else if (currentPage > highestMidPoint) {
      midPoint = highestMidPoint;
    }
    start = midPoint - offset;
    end = midPoint + offset;
  } else {
    start = 1;
    end = totalPages;
  }

  for (let j = start; j <= end; j++) {
    pageNumbers.push(j);
  }
  return pageNumbers;
}

function StepLink({ currentPage, location, disablePage, step, children }) {
  if (currentPage == disablePage) {
    return (
      <PaginationItem disabled>
        <PaginationLink tag="span">{children}</PaginationLink>
      </PaginationItem>
    );
  } 
    let urlQuery = new URLSearchParams(location.search);
    urlQuery.set('page', currentPage + step);
    return (
      <PaginationItem>
        <Link
          to={`${location.pathname}?${urlQuery.toString()}`}
          className="page-link"
        >
          {children}
        </Link>
      </PaginationItem>
    );
  
}

function PaginationInfo(props) {
  const totalPages = Math.ceil(props.totalEntries / props.perPage);
  return totalPages <= 1 ? null : (
    <Pagination>
      <StepLink {...props} step={-1} disablePage={1}>
        Previous
      </StepLink>
      {renderPageLinks(props)}
      <StepLink {...props} step={1} disablePage={totalPages}>
        Next
      </StepLink>
    </Pagination>
  );
}

PaginationInfo.defaultProps = {
  offset: 4,
  perPage: 50,
};

PaginationInfo.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalEntries: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  offset: PropTypes.number,
  location: PropTypes.object,
};

export default PaginationInfo;
