import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'material-ui/Button';

export function LinkButton({ to, text, color }) {
  return (
    <Link to={to}>
      <Button raised className="jr-btn float-right mr-3" color={color}>
        {text}
      </Button>
    </Link>
  );
}

export function CancelButton(props) {
  return <LinkButton {...props} />;
}

export function SubmitButton({ text }) {
  return (
    <Button
      raised
      type="submit"
      className="jr-btn text-white float-right"
      color="primary"
    >
      {text}
    </Button>
  );
}

CancelButton.defaultProps = { text: 'Cancel', color: 'default' };
SubmitButton.defaultProps = { text: 'Submit' };
