import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ContainerHeader from 'components/ContainerHeader/index';
import TagTable from 'app/routes/tags/Components/TagTable';
import TagForm from 'app/routes/tags/Components/TagForm';
import TagEditForm from 'app/routes/tags/Components/TagEditForm';

export default class Tags extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader match={this.props.match} title="Tags" />
          <Switch>
            <Route path={`${match.url}/new`} component={TagForm} />
            <Route path={`${match.url}/:tagId`} component={TagEditForm} />
            <Route path={`${match.url}/`} component={TagTable} />
          </Switch>
        </div>
      </div>
    );
  }
}
