import API from './../helper/api';
import {
  RESET_STATE,
  CLEAR_RESOURCE,
  REQUEST_RESOURCE,
  RECEIVE_RESOURCE_SUCCESS,
  RECEIVE_UPDATE_RESOURCE_SUCCESS,
  RECEIVE_CREATE_RESOURCE_SUCCESS,
} from './ActionType';

const api = new API();

function requestResource() {
  return {
    type: REQUEST_RESOURCE,
  };
}

function receiveResource(items) {
  return {
    type: RECEIVE_RESOURCE_SUCCESS,
    items,
  };
}

function receiveUpdateResource(item) {
  return {
    type: RECEIVE_UPDATE_RESOURCE_SUCCESS,
    item,
  };
}

function receiveCreateResource(item) {
  return {
    type: RECEIVE_CREATE_RESOURCE_SUCCESS,
    item,
  };
}

export function fetchResource(path) {
  return (dispatch) => {
    dispatch(requestResource());
    return api.get(`/${path}`).then((response) => {
      dispatch(receiveResource(response.records));
      return response;
    });
  };
}

export function createResource(path, item, callback) {
  return dispatch =>
    api.post(`/${path}`, item).then(() => {
      if (callback) callback();
    });
}

export function updateResource(path, item, callback) {
  return dispatch =>
    api
      .post(`/${path}/${item.id}`, item)
      .then((response) => {
        dispatch(receiveUpdateResource(response));
      })
      .then(() => {
        if (callback) callback();
      });
}

export function clearResource() {
  return {
    type: CLEAR_RESOURCE,
  };
}

export function resetState(state, defaultValue) {
  return {
    type: RESET_STATE,
    state,
    defaultValue,
  };
}
