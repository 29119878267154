import React from 'react';
import { connect } from 'react-redux';

import { fetchResource, clearResource } from 'actions/Resource';
import Loader from 'components/Loader';

class ProgressTable extends React.Component {
  componentDidMount() {
    this.props.fetchResource('config-progress');
  }

  componentWillUnmount() {
    this.props.clearResource();
  }

  render() {
    if (this.props.isFetching) {
      return <Loader />
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <div className="jr-card">
            <div className="table-responsive-material">
              <table className="default-table table-unbordered table table-sm table-hover">
                <thead className="th-border-b">
                  <tr>
                    <th>Title</th>
                    <th>Sent</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map((config, i) => (
                    <tr key={i}>
                      <td>{config.message && config.message.page_value}</td>
                      <td>
                        {config.numberOfProcessedTokens} /{' '}
                        {config.totalNumberOfTokens}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ resource }) => {
  return {
    items: resource.items,
    isFetching: resource.isFetching,
  };
};

export default connect(mapStateToProps, { fetchResource, clearResource })(ProgressTable);
