import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ContainerHeader from 'components/ContainerHeader/index';
import MessageTable from 'app/routes/messages/Components/MessageTable';
import MessageForm from 'app/routes/messages/Components/MessageForm';

export default class Messages extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader match={match} title="Marketing" />
          <Switch>
            <Route path={`${match.url}/new`} component={MessageForm} />
            <Route path={`${match.url}/:messageId`} component={MessageForm} />
            <Route path={`${match.url}/`} component={MessageTable} />
          </Switch>
        </div>
      </div>
    );
  }
}
