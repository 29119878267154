import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TextField, Checkbox, Button } from 'material-ui';
import { FormControlLabel } from 'material-ui/Form';

import CardBox from 'components/CardBox';
import { SubmitButton, CancelButton } from 'components/Buttons';
import { createResource, updateResource } from 'actions/Resource';

import API from './../../../../helper/api';

const api = new API();

class UserForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    userType: '',
    countryid: 1,
    is_active: false,
    errors: [],
  };

  componentDidMount() {
    const { userId } = this.props.match.params;
    if (userId) {
      api.get(`/adminusers/${userId}`).then((response) => {
        const {
          firstName,
          lastName,
          email,
          userType,
          countryid,
          is_active,
        } = response.data;

        this.setState({
          firstName,
          lastName,
          email,
          userType,
          countryid,
          is_active,
        });
      });
    }
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  displayError(errors) {
    return (
      <ul>
        {errors.map((error, i) => (
          <li key={i} className="text-danger">
            {error.msg}
          </li>
        ))}
      </ul>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { userId } = this.props.match.params;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      userType,
      countryid,
      is_active,
    } = this.state;

    const user = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      userType,
      countryid,
      is_active,
    };

    const res = userId
      ? this.props.updateResource('adminusers', { id: userId, ...user })
      : this.props.createResource('adminusers', user);

    res
      .then(() => this.setState({ resourceCreated: true }))
      .catch(({ response }) => this.setState({ errors: response.data.errors }));
  };

  render() {
    const { userId } = this.props.match.params;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      userType,
      countryid,
      is_active,
      errors,
      resourceCreated,
    } = this.state;

    if (resourceCreated) {
      return <Redirect to={'/app/adminusers'} />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <CardBox styleName="col-md-6 m-auto">
            <div>
              {this.displayError(errors)}
              <form onSubmit={this.handleSubmit}>
                <fieldset>
                  <TextField
                    required
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleInputChange}
                  />
                  <TextField
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    value={lastName}
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleInputChange}
                  />
                  <TextField
                    required
                    id="email"
                    label="Email"
                    name="email"
                    value={email}
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleInputChange}
                  />
                  <TextField
                    id="mobilePhone"
                    label="Phone Number"
                    name="mobilePhone"
                    value=""
                    fullWidth
                    disabled
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleInputChange}
                  />
                  <TextField
                    id="countryid"
                    select
                    name="countryid"
                    label="Country"
                    value={countryid}
                    onChange={this.handleInputChange}
                    SelectProps={{ native: true }}
                    margin="normal"
                    fullWidth
                  >
                    <option />
                    <option value="1">Nigeria</option>
                  </TextField>
                  <TextField
                    id="userType"
                    select
                    name="userType"
                    label="Privileges"
                    value={userType}
                    onChange={this.handleInputChange}
                    SelectProps={{ native: true }}
                    margin="normal"
                    fullWidth
                  >
                    <option />
                    <option value="User">User</option>
                    <option value="Admin">Admin</option>
                    <option value="SuperAdmin">Super Admin</option>
                  </TextField>
                  {!userId && (
                    <TextField
                      required
                      id="password"
                      label="Password"
                      name="password"
                      value={password}
                      fullWidth
                      margin="normal"
                      className="mt-1"
                      onChange={this.handleInputChange}
                    />
                  )}
                  {!userId && (
                    <TextField
                      required
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      fullWidth
                      type="password"
                      margin="normal"
                      className="mt-1"
                      onChange={this.handleInputChange}
                    />
                  )}

                  <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_active"
                          checked={is_active}
                          onChange={this.handleInputChange}
                        />
                      }
                      label="Active"
                    />
                  </div>
                  <SubmitButton />
                  <CancelButton to="/app/adminusers" />
                </fieldset>
              </form>
            </div>
          </CardBox>
        </div>
      </div>
    );
  }
}

export default connect(null, { createResource, updateResource })(UserForm);
