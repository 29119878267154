import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import ChatData from './Chat';
import Contact from './Contact';
import Mail from './Mail';
import ToDo from './ToDo';
import Dashboard from './Dashboard';
import Tag from './Tag';
import Service from './Service';
import Option from './Option';
import Resource from './Resource';
import Auth from './Auth';
import Alert from './Alert';

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  chatData: ChatData,
  contacts: Contact,
  mail: Mail,
  toDo: ToDo,
  dashboard: Dashboard,
  resource: Resource,
  tags: Tag,
  services: Service,
  options: Option,
  auth: Auth,
  alert: Alert,
});

export default reducers;
