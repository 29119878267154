import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format, subDays, differenceInDays} from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { Bar, BarChart, XAxis, YAxis, Tooltip, Line, LineChart } from 'recharts';
import { fetchStats, resetLoader } from 'actions/Dashboard';
import ContainerHeader from 'components/ContainerHeader/index';
import ReportBox from 'components/ReportBox/index';
import Loader from 'components/Loader';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import API from '../../../helper/api';

import {
  FETCH_AIRTIME_SMS_TRANSACTION,
  FETCH_DATA_SMS_TRANSACTION,
  FETCH_TAGGED_SMS,
  FETCH_UNTAGGED_SMS,
  FETCH_USERS,
} from '../../../actions/ActionType';

import {
  isEqual, isEmpty, DataProcessing
} from '../../../helper';

import './style.css';
import { flatten, getDatesBetweenDates } from '../../../helper/util';

/**
 * @description Creates data points while putting available data into consideration since the backend
 *  only returns data for available days
 * @param {object} param0 Object representing start and end date
 * @param {object} flattenedDataPoints flateened using the util/flatten
 * @returns {object} an object representing total and all data points needed to plot graph
 */
const generateDataPoints = ({ startDate, endDate }, flattenedDataPoints) => {
  let points = [];
  let total = 0;

  const datesBetweenDates = getDatesBetweenDates(startDate, endDate);
  
  for (const date of datesBetweenDates) {
    const formattedDate = format(date, "yyyy-MM-dd");

    const amt = flattenedDataPoints[formattedDate]
      ? flattenedDataPoints[formattedDate]
      : 0;

    points = [
      ...points,
      {
        amt,
        name: formattedDate,
      },
    ];
    total = total + amt;
  }

  return { points, total };
};

/**
 * @description Generated data needed to plot graph
 * @param {*} dataPoints Data points returned from the backend
 * @param {*} param1 Object representing start and end date
 * @returns data pointa for chart and total
 */
function makeGraphData(dataPoints = [], { startDate, endDate }) {
  const generatedDataPoints = generateDataPoints(
    {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    },
    flatten(dataPoints, 'datetime', 'total')
  );

  return {
    chart: generatedDataPoints.points,
    total: generatedDataPoints.total,
  };
}

const tickFormatter = date => format(new Date(date), 'dd MMM');

const api = new API();
class StatDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersPayload: null,
      taggedSMSPayload: null,
      untaggedSMSPayload: null,
      airtimeSMSTranxPayload: null,
      dataSMSTranxPayload: null,
      customDaysActive: false,
      activateLoading: true,
      startDate: new Date(subDays(new Date(), 15)),
      endDate: new Date(),
      showCustomDateDropdown: false,
    };
    this.getTelecomsStats = this.getTelecomsStats.bind(this);
    this.getBankTransactions = this.getBankTransactions.bind(this);
    this.getTelcosTransactions = this.getTelcosTransactions.bind(this);
    this.dateRangeLabel = 'Last 15 days';
    this.daysDifferentInDateRange = 15;
    this.dateRangeTitle = 'Last 15 days';

  }
  componentDidMount() {
    this.getAllData();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.users) && !isEqual(nextProps.users, this.props.users)) {

      const { users } = nextProps;
      if (users.success) {
        this.setState({
          usersPayload: this.dataProcessForChart(users.data)
        });
      }
    }

    // ---------------
    if (!isEmpty(nextProps.taggedSMS) && !isEqual(nextProps.taggedSMS, this.props.taggedSMS)) {
      const { taggedSMS } = nextProps;
      if (taggedSMS.success) {
        this.setState({
          taggedSMSPayload: makeGraphData(taggedSMS.data, {
            startDate: this.state.startDate,
            endDate: this.state.endDate
          })
        }); 
      }
    }

    if (!isEmpty(nextProps.untaggedSMS) && !isEqual(nextProps.untaggedSMS, this.props.untaggedSMS)) {
      const { untaggedSMS } = nextProps;
      if (untaggedSMS.success) {
        this.setState({
          untaggedSMSPayload: makeGraphData(untaggedSMS.data, {
            startDate: this.state.startDate,
            endDate: this.state.endDate
          })
        });
      }
    }
    // ---------------

    if (!isEmpty(nextProps.airtimeSMSTranx) && !isEqual(nextProps.airtimeSMSTranx, this.props.airtimeSMSTranx)) {
      const { airtimeSMSTranx } = nextProps;
      if (airtimeSMSTranx.success) {
        this.setState({
          airtimeSMSTranxPayload: this.dataProcessForChart(airtimeSMSTranx.data)
        });
      }
    }

    if (!isEmpty(nextProps.dataSMSTranx) && !isEqual(nextProps.dataSMSTranx, this.props.dataSMSTranx)) {
      const { dataSMSTranx } = nextProps;
      if (dataSMSTranx.success) {
        this.setState({
          dataSMSTranxPayload: this.dataProcessForChart(dataSMSTranx.data)
        });
      }
    }

    if (!isEmpty(nextProps.resetLoading) && !isEqual(nextProps.resetLoading, this.props.resetLoading)) {
      const { resetLoading } = nextProps;
      this.onDeactivateLoading(resetLoading);
    }
  }

  getAllData = (data = {}) => {
    const { fetchStats } = this.props;

    fetchStats(FETCH_USERS, 'userStats', data);
    fetchStats(FETCH_TAGGED_SMS, 'tagged-sms', data);
    fetchStats(FETCH_UNTAGGED_SMS, 'untagged-sms', data);
    fetchStats(FETCH_AIRTIME_SMS_TRANSACTION, 'telco-aitime-transaction', data);
    fetchStats(FETCH_DATA_SMS_TRANSACTION, 'telco-data-transaction', data);
  }

  dataProcessForChart = (data) => {
    const dataProcess = new DataProcessing({
      defaultProps: {
        total: 0,
      },
      dataProps: ['total'],
      range: this.daysDifferentInDateRange
    });

    dataProcess.prepareDataForChart(data);

    const dataForChart = {
      ...dataProcess.defaultChartRangeData,
      ...dataProcess.chartRangeData
    };

    let total = 0;
    const chart = [];

    Object.keys(dataForChart).forEach((key) => {
      chart.push({
        name: key,
        amt: dataForChart[key].total
      });
      total += dataForChart[key].total;
    });

    return ({total, chart});
  }

  getTelecomsStats() { // why do this instead of following the laid down way??? could not figure the laid down way in time.. so i do the normal way...
    api.get('/telecomsStats').then((res) => {

      const response = res.data;

      this.setState({
        telecomsStats: [
          {
            stat: 'Airtime',
            value: response.airtime_count
          },
          {
            stat: 'Data',
            value: response.data_count
          },
        ]
      });

    });
  }
  getBankTransactions() { // why do this instead of following the laid down way??? could not figure the laid down way in time.. so i do the normal way...
    api.get('/bankTransactionsStats').then((res) => {

      const response = res.data;
      this.setState({
        bankTransactions: response
      });

    });
  }
  getTelcosTransactions() { // why do this instead of following the laid down way??? could not figure the laid down way in time.. so i do the normal way...
    api.get('/telcosTransactionsStats').then((res) => {

      const response = res.data;
      this.setState({
        telcosTransactions: response
      });

    });
  }

  OnFetchData = (data) => {
    const { onResetLoader } = this.props;
    const { startDate, endDate} = data;

    // Reset Loader
    onResetLoader();

    this.getAllData(data);
    this.setState({
      customDaysActive: false,
      activateLoading: true,
      showCustomDateDropdown: false,
      startDate,
      endDate
    });
  }

  onHandleDateChange = (data) => {
    const { showCustomDateDropdown } = this.state;

    if (data.name === 'Custom Range') {
      this.dateRangeLabel = data.name;
      this.setState({
        showCustomDateDropdown: !showCustomDateDropdown
      });
    } else if (this.dateRangeLabel !== data.name) {
      this.dateRangeLabel = data.name;
      this.dateRangeTitle = data.name;
      this.daysDifferentInDateRange = data.numOfDays;
      const currentDate = new Date();
      const startDate = format(subDays(currentDate, data.numOfDays), 'yyyy-MM-dd');
      const endDate = format(currentDate, 'yyyy-MM-dd');

      this.OnFetchData({startDate, endDate});

    }
  }

  handleDateChange = (dateType, date) => {
    this.setState({
      [dateType]: date
    });
  };

  onDeactivateLoading = (resetLoading) => {
    const {
      user,
      tagged,
      untagged,
      airtime,
      data,
    } = resetLoading;
    if (
      !user
      && !tagged
      && !untagged
      && !airtime
      && !data
    ) {
      this.setState({
        activateLoading: false
      });
    }
  }

  onViewDataWithCustomDate = () => {
    const { startDate, endDate } = this.state;

    const customDateDiff = differenceInDays(new Date(endDate), new Date(startDate));

    if (customDateDiff >= 1) {
      this.daysDifferentInDateRange = customDateDiff;
      const startDateFormat = format(new Date(startDate), 'MMM do, yyyy'); // May 15th 2019
      const endDateFormat = format(new Date(endDate), 'MMM do, yyyy');
      this.dateRangeTitle = `${startDateFormat} => ${endDateFormat}`;
      this.OnFetchData({
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      });
    }
  }

  componentWillUnmount() {
    const { onResetLoader } = this.props;
    onResetLoader();
  }

  render() {
    const {
      usersPayload,
      taggedSMSPayload,
      untaggedSMSPayload,
      airtimeSMSTranxPayload,
      dataSMSTranxPayload,
      activateLoading,
      startDate,
      endDate,
      showCustomDateDropdown
    } = this.state;

    const dataDateRanges = [
      {name: 'Last 7 days', numOfDays: 7},
      {name: 'Last 15 days', numOfDays: 15},
      {name: 'Last 30 days', numOfDays: 30},
      {name: 'Last 60 days', numOfDays: 60},
      {name: 'Last 90 days', numOfDays: 90},
      {name: 'Custom Range', numOfDays: 'custom'},
    ];

    return (
      <React.Fragment>
        {activateLoading ? (
          <Loader />
        ) : (
          <div className="app-wrapper dashboard">
            <div className="dashboard animated slideInUpTiny animation-duration-3">
              <ContainerHeader match={this.props.match} title="Dashboard" />
              <div className="row" style={{ marginBottom: 30 }}>
                <div className="col-xl-12 col-sm-12">
                  <h3 className="date-range-title">{this.dateRangeTitle}</h3>
                </div>
                <div className="col-xl-12 col-sm-12">
                  <div className="sage-btn-wrapper">
                    {dataDateRanges.map((datum, index) => (
                      <div
                        role="button"
                        key={datum.name}
                        tabIndex={index}
                        onClick={() => this.onHandleDateChange(datum)}
                        title={
                          datum.numOfDays === "custom"
                            ? `${
                                showCustomDateDropdown ? "Hide" : "Show"
                              } Custom Date`
                            : ""
                        }
                        className={`sage-button${
                          this.dateRangeLabel !== datum.name ? "" : " active"
                        }${
                          datum.numOfDays === "custom" ? " custom-date-btn" : ""
                        }${
                          datum.numOfDays === "custom" && showCustomDateDropdown
                            ? " visible"
                            : ""
                        }`}
                      >
                        {datum.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-xl-12 col-sm-12">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div style={{ position: "relative" }}>
                      <Grid
                        container
                        justify="space-around"
                        className={`custom-date-wrapper${
                          showCustomDateDropdown ? " visible" : ""
                        }`}
                      >
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          label="Start Date"
                          value={startDate}
                          onChange={(date) =>
                            this.handleDateChange("startDate", date)
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          label="End Date"
                          value={endDate}
                          onChange={(date) =>
                            this.handleDateChange("endDate", date)
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />

                        <div
                          role="button"
                          tabIndex={-1}
                          className="sage-button date-view-data"
                          onClick={this.onViewDataWithCustomDate}
                        >
                          View Data
                        </div>
                      </Grid>
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="row">
                {!isEmpty(usersPayload) ? (
                  <div className="col-xl-12 col-sm-12">
                    <ReportBox
                      heading="Users"
                      title={usersPayload.total}
                      detail={this.dateRangeTitle}
                    >
                      <BarChart data={usersPayload.chart}>
                        <Bar dataKey="amt" fill="#3f51b5" />
                        <Tooltip />
                        <XAxis stroke="#3f51b5" dataKey="name" tickFormatter={tickFormatter} />
                        <YAxis
                          allowDecimals={false}
                          stroke="#3f51b5"
                          dataKey="amt"
                        />
                      </BarChart>
                    </ReportBox>
                  </div>
                ) : null}

                {!isEmpty(taggedSMSPayload) ? (
                  <div className="col-xl-12 col-sm-12">
                    <ReportBox
                      heading="Tagged Bank Transactions"
                      title={taggedSMSPayload.total}
                      detail={this.dateRangeTitle}
                    >
                      <LineChart data={taggedSMSPayload.chart}>
                        <XAxis
                          stroke="#3f51b5"
                          dataKey="name"
                          tickFormatter={tickFormatter}
                          ticks={[
                            taggedSMSPayload.chart[0].name,
                            taggedSMSPayload.chart[
                              Math.round(taggedSMSPayload.chart.length / 2)
                            ].name,
                            taggedSMSPayload.chart[
                              taggedSMSPayload.chart.length - 1
                            ].name,
                          ]}
                        />
                        <YAxis
                          allowDecimals={false}
                          stroke="#3f51b5"
                          dataKey="amt"
                          interval="preserveStart"
                        />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dot={false}
                          dataKey="amt"
                          stroke="#3f51b5"
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ReportBox>
                  </div>
                ) : null}

                {!isEmpty(untaggedSMSPayload) ? (
                  <div className="col-xl-12 col-sm-12">
                    <ReportBox
                      heading="Untagged Bank Transactions"
                      title={untaggedSMSPayload.total}
                      detail={this.dateRangeTitle}
                    >
                      <LineChart
                        width={300}
                        height={100}
                        data={untaggedSMSPayload.chart}
                      >
                        <XAxis
                          stroke="#3f51b5"
                          dataKey="name"
                          tickFormatter={tickFormatter}
                          ticks={[
                            untaggedSMSPayload.chart[0].name,
                            untaggedSMSPayload.chart[
                              Math.round(untaggedSMSPayload.chart.length / 2)
                            ].name,
                            untaggedSMSPayload.chart[
                              untaggedSMSPayload.chart.length - 1
                            ].name,
                          ]}
                        />
                        <YAxis stroke="#3f51b5" dataKey="amt" />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dot={false}
                          dataKey="amt"
                          stroke="#3f51b5"
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ReportBox>
                  </div>
                ) : null}

                {!isEmpty(airtimeSMSTranxPayload) ? (
                  <div className="col-xl-12 col-sm-12">
                    <ReportBox
                      heading="Telco Airtime Transactions"
                      title={airtimeSMSTranxPayload.total}
                      detail={this.dateRangeTitle}
                    >
                      <BarChart data={airtimeSMSTranxPayload.chart}>
                        <Bar dataKey="amt" fill="#3f51b5" />
                        <Tooltip />
                        <XAxis stroke="#3f51b5" dataKey="name" tickFormatter={tickFormatter}/>
                        <YAxis
                          allowDecimals={false}
                          stroke="#3f51b5"
                          dataKey="amt"
                        />
                      </BarChart>
                    </ReportBox>
                  </div>
                ) : null}

                {!isEmpty(dataSMSTranxPayload) ? (
                  <div className="col-xl-12 col-sm-12">
                    <ReportBox
                      heading="Telco Data Subscriptions Transactions"
                      title={dataSMSTranxPayload.total}
                      detail={this.dateRangeTitle}
                    >
                      <BarChart data={dataSMSTranxPayload.chart}>
                        <Bar dataKey="amt" fill="#3f51b5" />
                        <Tooltip />
                        <XAxis stroke="#3f51b5" dataKey="name" tickFormatter={tickFormatter} />
                        <YAxis
                          allowDecimals={false}
                          stroke="#3f51b5"
                          dataKey="amt"
                        />
                      </BarChart>
                    </ReportBox>
                  </div>
                ) : null}

              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ dashboard }) => ({
  userStats: dashboard.userStats,
  bankAlertStats: dashboard.bankAlertStats,
  billPaymentsStats: dashboard.billPaymentsStats,
  paymentTransactionStats: dashboard.paymentTransactionStats,
  tagsStats: dashboard.tagsStats,
  topTagsStats: dashboard.topTagsStats,
  isFetching: dashboard.isFetching,
  users: dashboard.users,
  taggedSMS: dashboard.taggedSMS,
  untaggedSMS: dashboard.untaggedSMS,
  airtimeSMSTranx: dashboard.airtimeSMSTranx,
  dataSMSTranx: dashboard.dataSMSTranx,
  resetLoading: dashboard.loader,
});

export default connect(mapStateToProps, { fetchStats, onResetLoader: resetLoader })(StatDashboard);
