/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "material-ui/Button";
import socketIOClient from "socket.io-client";
import { CircularProgress } from "material-ui/Progress";
import API from "../../helper/api";
import { getLocalStorage } from "../../helper/storage";
import { formatNumber } from '../../helper/util';

function Menu({ title, children }) {
  const [open, setOpen] = useState(true);
  const toggle = () => setOpen((open) => !open);
  const close = () => setOpen(false);

  return (
    <li>
      <Button href="javascript:void(0)" onClick={toggle}>
        <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
        <span className="nav-text">{title}</span>
      </Button>
      {open && <ul className="sub-menu">{children({ close })}</ul>}
    </li>
  );
}

function AutoTagtransactions() {
  const [processing, setProcessing] = useState(false);
  const [starting, setStarting] = useState(false);
  const [processMetaData, setProcessMetaData] = useState({});

  const startAutoTagTransactions = async () => {
    const api = new API();
    try {
      setStarting(true);
      await api.get(`/transactions/auto-tag`);
    } finally {
      setStarting(false);
    }
  };

  useEffect(() => {
    const ioClient = socketIOClient(process.env.NODE_HOST);

    const user = getLocalStorage("user");

    ioClient.emit("join", {
      autoTagRoom: `autoTagProgress_${user.id}`,
    });

    ioClient.on(`autoTagProgress_${user.id}`, (payload) => {
      setProcessing(true);
      setProcessMetaData(payload);
    });

    return () => {
      ioClient.off(`autoTagProgress_${user.id}`);
    };
  }, [setProcessMetaData, setProcessing]);

  const { done, current, last, taggedLength } = processMetaData;

  return (
    <li onClick={!processing && startAutoTagTransactions}>
      <a href="javascript:void(0)">
        <span className="nav-text">AutoTag Transactions</span>
        {starting && (
          <CircularProgress
            style={{
              width: 18,
              height: 18,
              marginLeft: 13,
              verticalAlign: "middle",
            }}
          />
        )}
        <br />
        {current && (
          <div
            style={{
              fontWeight: "bold",
              border: "1px solid gray",
              padding: "5px",
              marginTop: "2px",
              borderRadius: "0.5rem",
              display: "inline-block",
            }}
          >
            {!done &&
              `${formatNumber(current)} of ${formatNumber(
                last
              )} (${formatNumber(taggedLength)} tagged)`}
          </div>
        )}
      </a>
    </li>
  );
}

function SideNavContent() {
  const currentUserEmail = getLocalStorage("user").email
  return (
    <ul className="nav-menu">
      <li className="nav-header">Main</li>

      {
        currentUserEmail !== "viewer@kliqr.com" ?
        <li className="menu no-arrow">
          <Link to="/app/admin-dashboard">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">Dashboard</span>
          </Link>
        </li>
        : null
      }
 
      {
        currentUserEmail !== "viewer@kliqr.com" ?
        <li className="menu no-arrow">
          <Link to="/app/tags">
            <i className="zmdi zmdi-tag zmdi-hc-fw" />
            <span className="nav-text">Tags</span>
          </Link>
        </li>
        : null
      }

      {
        currentUserEmail !== "viewer@kliqr.com" ?
        <li className="menu no-arrow">
          <Link to="/app/services">
            <i className="zmdi zmdi-view-list-alt zmdi-hc-fw" />
            <span className="nav-text">Services</span>
          </Link>
        </li>
        : null
      }

      {
        currentUserEmail !== "viewer@kliqr.com" ?
        <li className="menu no-arrow">
          <Link to="/app/options">
            <i className="zmdi zmdi-view-list-alt zmdi-hc-fw" />
            <span className="nav-text">Options</span>
          </Link>
        </li>
        : null
      }

      {
        currentUserEmail !== "viewer@kliqr.com" ?
        <li className="menu no-arrow">
          <Link to="/app/messages">
            <i className="zmdi zmdi-view-list-alt zmdi-hc-fw" />
            <span className="nav-text">Marketing</span>
          </Link>
        </li>
        : null
      }

      {
        currentUserEmail !== "viewer@kliqr.com" ?
        <li className="menu no-arrow">
          <Link to="/app/adminusers">
            <i className="zmdi zmdi-view-list-alt zmdi-hc-fw" />
            <span className="nav-text">Admin Users</span>
          </Link>
        </li>
        : null
      }
      
      <li className="menu no-arrow">
        <Link to="/app/unknown-sms-alerts">
          <i className="zmdi zmdi-view-list-alt zmdi-hc-fw" />
          <span className="nav-text">Unknown sms alerts</span>
        </Link>
      </li>

      <Menu title="Config">{({ close }) => [<AutoTagtransactions />]}</Menu>
    </ul>
  );
}

export default withRouter(SideNavContent);
