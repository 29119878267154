import { propsAvailable } from './util';


/**
 * @description Set a local storage value with expired date, if expires is not set
 * 24 hours will be set
 * @param {String} name columnName (columnName) of the local storagr value
 * @param {any} value value to associate with the columnName
 * @param {Number} expires number of hours that the columnName will be available
 */
export function setLocalStorage(name, value, expires) {
  if (expires === undefined || expires === null) {
    expire = (12 * 60 * 60);
  } else {
    expire = Math.abs(expires);
  }

  const now = Date.now();
  const schedule = (now + expire) * 1000;
  try {
    if (typeof value === 'string' || typeof value === 'number') {
      window.localStorage.setItem(name, `${value}`);
    } else {
      window.localStorage.setItem(name, JSON.stringify(value));
    }
    window.localStorage.setItem(`${name}_expiresIn`, schedule.toString(10));
    return true;
  } catch (e) {
    console.log(`Error: ${e}`);
    return false;
  }
}


/**
 *
 * @param {String} name columnName (columnName) to be remove
 */
export function removeLocalStorage(name) {
  try {
    window.localStorage.removeItem(name);
    window.localStorage.removeItem(`${name}_expiresIn`);
  } catch (e) {
    console.log('Error: ', e);
    return false;
  }
  return true;
}


/**
 *
 * @param {String} name columnName (columnName) to retrieve the data set
 */
export function getLocalStorage(name) {
  const now = Date.now();

  let expiresIn = parseInt(window.localStorage.getItem(`${name}_expiresIn`), 10);
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }
  if (expiresIn < now) {
    removeLocalStorage(name);
    return null;
  }
  if (propsAvailable(name, window.localStorage)) {
    const getItem = window.localStorage.getItem(name);
    try {
      return JSON.parse(getItem);
    } catch (e) {
      return getItem;
    }
  } else {
    return null;
  }

}

