export function invalidMinLength(value, min) {
  
  return `${value}`.length < min;
}

export function invalidMaxLength(value, max) {
  return `${value}`.length > max;
}

export function invalidAllowExts(value, extensions) {
  return !Array.isArray(extensions) || extensions.indexOf(value) === -1;
}

export function invalidEmail(value) {
  return !(/[a-zA-Z0-9._#~&-]+@[a-zA-Z0-9._#~&-]+\.[a-z]{2,}/.test(value));
}

export function invalidCompare(value, compareValue) {
  return value !== compareValue;
}

export const min = (value, minimum) => value < minimum;

export const max = (value, maximum) => value < maximum;

function cardLengthValid(card, value) {
  switch (card) {
    case 'visa':
      return [13, 16].indexOf(value.length) === -1;
    case 'mastercard':
      return [16].indexOf(value.length) === -1;
    case 'americaExpress':
      return [15].indexOf(value.length) === -1;
    // eslint-disable-next-line no-case-declarations
    case 'discover':
      const lendis = value.indexOf('5') === 0 ? 15 : 16;
      return [lendis].indexOf(value.length) === -1;
    case 'dinersClub':
      return [14].indexOf(value.length) === -1;
    // eslint-disable-next-line no-case-declarations
    case 'jcb':
      const lenjcb = value.indexOf('35') === 0 ? 16 : 15;
      return [lenjcb].indexOf(value.length) === -1;
    case 'verve':
      return [19].indexOf(value.length) === -1;
    default:
      return true;
  }
}

export function invalidCard(value) {
  // To check the first unique card
  const validShortCards = {
    americaExpress: /^3[47]/,
    visa: /^4/,
    mastercard: /^5[1-5]/,
    discover: /^(6011|5)/,
    dinersClub: /^3(0[0-5]|[68])/,
    jcb: /^(2131|1800|35)/,
    verve: /^506(0|1)/,
  };

  let cardType = 'credit';

  const str = value.replace(/ /g, '');
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-restricted-syntax
  for (const key in validShortCards) {
    if ((new RegExp(validShortCards[key])).test(str)) {
      cardType = key === 'discover' && validShortCards.verve.test(str) ? 'verve' : key;
      break;
    }
  }
  return {status: cardLengthValid(cardType, str), cardType };
}
