import {
  REQUEST_TAGS,
  RECEIVE_TAGS,
  RECEIVE_UPDATE_TAG,
} from 'actions/ActionType';

const INIT_STATE = {
  tags: []
};

function updateTag(state = [], action) {
  return state.map(tag => {
    if (tag.id == action.tag.id ) {
      return {
        ...tag,
        ...action.tag,
      }
    }
    return tag
  });
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_TAGS:
      return {
        ...state,
        isFetching: true
      }
    case RECEIVE_TAGS:
      return {
        ...state,
        isFetching: false,
        tags: action.tags
      }
    case RECEIVE_UPDATE_TAG:
      return {
        ...state,
        tags: updateTag(state.tags, action),
      }
    default:
      return state;
  }
}
