import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TextField, Checkbox, Button, Select } from 'material-ui';
import { FormControlLabel } from 'material-ui/Form';
import { MenuItem } from 'material-ui/Menu';

import CardBox from 'components/CardBox';
import { SubmitButton, CancelButton } from 'components/Buttons';
import { createResource, updateResource } from 'actions/Resource';


import API from './../../../../helper/api';
import {isEmpty} from './../../../../helper/util';

const api = new API();
class OptionForm extends Component {
  state = {
    name: '',
    detail: '',
    amount: '',
    services: [],
    service: '',
    status: false,
    commission: '',
    paymentOption: '',
    commissionType: '',
    acceptsInput: false,
  };

  componentDidMount() {
    const { optionId } = this.props.match.params;

    api
      .get('/services?page=0')
      .then((resp) => {
        const {records} = resp;
        this.setState({ services: records});
      });

    if (optionId) {
      api.get(`/options/${optionId}`).then((response) => {
        console.log(response);
        const {
          name,
          amount,
          detail,
          commission,
          paymentOption,
          is_active: status,
          services_id: service,
          accepts_input: acceptsInput,
          commission_type: commissionType,
        } = response;

        this.setState({
          name,
          amount,
          detail,
          status,
          commission,
          service,
          acceptsInput,
          paymentOption,
          commissionType,
        });
      });
    }
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleCheckBoxChange = name => (event) => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { optionId } = this.props.match.params;
    const {
      name,
      amount,
      detail,
      commission,
      paymentOption,
      status: is_active,
      service: services_id,
      acceptsInput: accepts_input,
      commissionType: commission_type,
    } = this.state;

    const option = {
      name,
      amount,
      detail,
      is_active,
      commission,
      services_id,
      accepts_input,
      paymentOption,
      commission_type,
    };

    if (optionId) {
      this.props.updateResource('options', { id: optionId, ...option }, this.redirect);
    } else {
      this.props.createResource('options', option, this.redirect);
    }
  };

  redirect = () => {
    this.setState({ resourceCreated: true });
  };

  render() {
    const {
      name,
      detail,
      amount,
      commission,
      status,
      acceptsInput,
      service,
      services,
      paymentOption,
      commissionType,
      resourceCreated,
    } = this.state;

    console.log(acceptsInput);

    if (resourceCreated) {
      return <Redirect to={'/app/options'} />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <CardBox styleName="col-md-6 m-auto">
            <form onSubmit={this.handleSubmit}>
              <fieldset>
                <TextField
                  id="service"
                  select
                  name="service"
                  label="Service"
                  value={service}
                  onChange={this.handleInputChange}
                  SelectProps={{ native: true }}
                  margin="normal"
                  fullWidth
                >
                  <option />
                  {services.map((option, i) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
                <TextField
                  id="name"
                  label="Name"
                  name="name"
                  value={name}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="detail"
                  label="Detail"
                  name="detail"
                  value={detail}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="amount"
                  label="Amount"
                  name="amount"
                  value={amount}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="commissionType"
                  select
                  name="commissionType"
                  label="Commission Type"
                  value={commissionType}
                  onChange={this.handleInputChange}
                  SelectProps={{ native: true }}
                  helperText="Please select a commission type"
                  margin="normal"
                  fullWidth
                >
                  <option />
                  <option value="0">Amount</option>
                  <option value="1">Percentage</option>
                </TextField>
                <TextField
                  id="commission"
                  label="Commission"
                  name="commission"
                  value={commission}
                  fullWidth
                  margin="normal"
                  className="mt-1"
                  onChange={this.handleInputChange}
                />
                <TextField
                  id="paymentOption"
                  select
                  name="paymentOption"
                  label="Payment Option"
                  value={paymentOption}
                  onChange={this.handleInputChange}
                  SelectProps={{ native: true }}
                  helperText="Please select a payment option"
                  margin="normal"
                  fullWidth
                >
                  <option />
                  <option value="1">Quick Teller</option>
                  <option value="2">GTPay</option>
                  <option value="3">Interswitch</option>
                  <option value="4">Visa</option>
                </TextField>
                <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceptsInput"
                        checked={isEmpty(acceptsInput) ? false : acceptsInput}
                        onChange={this.handleCheckBoxChange('acceptsInput')}
                      />
                    }
                    label="Accepts Input"
                  />
                </div>
                <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="status"
                        checked={typeof status === 'boolean' ? status : status === 1}
                        onChange={this.handleCheckBoxChange('status')}
                      />
                    }
                    label="Active"
                  />
                </div>

                <SubmitButton />
                <CancelButton to="/app/options" />
              </fieldset>
            </form>
          </CardBox>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  createResource,
  updateResource,
})(OptionForm);
