import { addDays, subDays, format, differenceInDays, subWeeks, subMonths, addHours, differenceInHours } from 'date-fns';
import { isEmpty, deepPropsExist } from './util';

export default class DataProcessing {
  constructor(
    {
      defaultProps,
      transactionType,
      dataProps,
      range,
      propField = 'datetime',
      increment = 'total'
    }
  ) {
    this.chartRangeData = {};
    this.currentStartDate = null;
    this.currentEndDate = null;
    this.currentDate = null;
    this.defaultChartRangeData = {};
    this.startDate = null;
    this.endDate = null;
    this.defaultProps = defaultProps;
    this.transactionType = transactionType || null;
    this.dataProps = dataProps;
    this.dateRanges = [];
    this.range = range;
    this.propField = propField;
    this.increment = increment;
  }

    getAxisWidthProps = (dateFormat) => {
      const range = parseInt(this.range, 10);
      const numOfPts = range <= 30 ? range : 30;
      return ({
        start_date: format(subDays(new Date(), range), dateFormat),
        modifyFunc: addDays,
        numOfPts,
        diffFunc: differenceInDays,
      });
    }


    getDateRange = ({
      numOfPts,
      modifyFunc,
      diffFunc,
      dateFormat,
      start_date,
    }) => {
      const xAxisNumPoints = diffFunc(new Date(), new Date(start_date));

      const axisWidth = (xAxisNumPoints / numOfPts).toFixed(2);
      const halfAxisWidth = parseFloat((axisWidth / 2).toFixed(2));
      // Prepare the date range for x-axis
      for (let i = 0; i < numOfPts; i++) {
        const midDate = format(modifyFunc(
          new Date(start_date),
          ((i * axisWidth) + halfAxisWidth).toFixed(2)
        ), dateFormat);

        this.dateRanges.push({
          startDate: format(modifyFunc(
            new Date(start_date),
            i * axisWidth
          ), dateFormat),
          midDate,
          endDate: format(modifyFunc(
            new Date(start_date),
            ((i + 1) * axisWidth).toFixed(2)
          ), dateFormat),
        });
        this.defaultChartRangeData = {...this.defaultChartRangeData, ...{[midDate]: {...this.defaultProps}}};
      }

      // Make that endDate of the last
      // element by today's date
      const lastIndex = numOfPts - 1;
      let lastDate = this.dateRanges[lastIndex];
      lastDate = {...lastDate, midDate: format(new Date(), dateFormat), endDate: format(new Date(), dateFormat)};
      this.dateRanges[lastIndex] = lastDate;
    }

    setChartRangeProps = (datum) => {

      for (const dateRange of this.dateRanges) {



        if (
          datum[this.propField] >= dateRange.startDate
          && datum[this.propField] < dateRange.endDate
        ) {
          this.chartRangeData[dateRange.midDate] = {...this.defaultProps};
          this.currentStartDate = dateRange.startDate;
          this.currentEndDate = dateRange.endDate;
          this.currentDate = dateRange.midDate;

          if (!isEmpty(this.transactionType)) {
            const transactionType = this.transactionType(datum);
            this.chartRangeData[this.currentDate][transactionType] += deepPropsExist(datum, this.increment) && (typeof datum[this.increment]).toLowerCase() === 'number' ? parseInt(datum[this.increment], 10) : 1;
          }

          this.dataProps.forEach((dataProp) => {
            this.chartRangeData[this.currentDate][dataProp] += deepPropsExist(datum, this.increment) && (typeof datum[this.increment]).toLowerCase() === 'number' ? parseInt(datum[this.increment], 10) : 1;
          });
          break;
        }
      }

    }

    transactionCategorized = (datum) => {
      if (
        datum[this.propField] >= this.currentStartDate
        && datum[this.propField] < this.currentEndDate
      ) {
        if (!isEmpty(this.transactionType)) {
          const transactionType = this.transactionType(datum);
          this.chartRangeData[this.currentDate][transactionType] += deepPropsExist(datum, this.increment) && (typeof datum[this.increment]).toLowerCase() === 'number' ? parseInt(datum[this.increment], 10) : 1;
        }

        this.dataProps.forEach((dataProp) => {
          // If datum[this.increment] typeof is number
          // Add datum[this.increment] else add 1
          this.chartRangeData[this.currentDate][dataProp] += deepPropsExist(datum, this.increment) && (typeof datum[this.increment]).toLowerCase() === 'number' ? parseInt(datum[this.increment], 10) : 1;
        });
      } else {
        // Check which date range to use
        this.setChartRangeProps(datum, this.dateRanges);
      }
    }

    prepareDataForChart = (data = []) => {

      const dateFormat = 'yyyy-MM-dd';


      const { start_date, modifyFunc, diffFunc, numOfPts} = this.getAxisWidthProps(dateFormat);

      this.startDate = format(new Date(start_date),
        'MMM dd, yy'
      );

      this.endDate = format(new Date(),
        'MMM dd, yy'
      );

      // Generate date range
      this.getDateRange({
        start_date,
        modifyFunc,
        diffFunc,
        numOfPts,
        dateFormat
      });

      const [firstDateRange] = this.dateRanges;

      if (isEmpty(firstDateRange)) {
        this.chartRangeData = {};
      } else {
        this.currentStartDate = firstDateRange.startDate;
        this.currentEndDate = firstDateRange.endDate;
        this.currentDate = firstDateRange.midDate;

        this.chartRangeData = {...this.chartRangeData, ...{[this.currentDate]: {...this.defaultProps}}};

        if (!Array.isArray(data)) return;
        data.forEach((datum) => {
          this.transactionCategorized(datum);
        });
      }
    }


}
