export const removeTags = (str) => {
  const rex = /(<([^>]+)>)/ig;
  return str.replace(rex, '')
    .replace(/&#8211;/g, '-')
    .replace(/&#8217;/g, "'")
    .replace(/&nbsp;/g, ' ')
    .replace(/\[&hellip;\]/g, '...')
  ;
};

export const camelCaseToSnakeFormat = (str, glue = '_') => str.replace(/([a-z])([A-Z])/g, `$1${glue}$2`).toLowerCase();


export const acronym = (str, len = 2) => {
  const strFormatted = camelCaseToSnakeFormat(str);
  let acronymWord = '';
  strFormatted.split('_').forEach((word) => {
    acronymWord += word.substring(0, len);
  });
  return acronymWord;
};

export const cardIcon = (cardType) => {
  switch (cardType) {
    case 'visa':
    case 'mastercard':
    case 'jcb':
    case 'discover':
      return `cc-${cardType}`;
    case 'americaExpress':
      return `cc-${acronym(cardType, 2)}`;
    case 'dinersClub':
      return 'cc-diners-club';
    case 'verve':
    case 'credit':
      return 'credit-card';
    default:
      return true;
  }
};

export const creditCardFormat = (str) => {
  const strFormrt = str.replace(/ /g, '')
    .replace(/\d{4}/g, match => (`${match} `)).trim();
  return strFormrt;
};

export const strPad = (str, len, pad = '*', direction = 'left') => {
  const strLen = str.length;
  let start;
  let end;
  switch (direction) {
    case 'right':
      start = len >= strLen ? 0 : strLen - len;
      end = strLen;
      break;
    case 'middle':
      start = len >= strLen ? 0 : Math.floor((strLen - len) / 2);
      end = len >= strLen ? strLen : start + len;
      break;
    default:
      start = 0;
      end = len >= strLen ? strLen : len;
  }
  const zeroIndexStart = start;
  const zeroIndexEnd = end;
  const arrLen = len <= strLen ? strLen : len;
  const arrPadding = [...Array(arrLen)].map((_, i) => (i >= zeroIndexStart && i < zeroIndexEnd ? pad : str[i]));

  return arrPadding.join('');
};

export const leadZero = (str, len, pad = '0', direction = 'left') => {
  const strConvert = `${str}`;
  const strLen = strConvert.length;
  let start;
  let arrLen;
  if (strLen >= len) {
    start = 0;
    arrLen = strLen;
  } else {
    start = direction === 'right' ? 0 : len - strLen;
    arrLen = len;
  }
  const arrPadding = [...Array(arrLen)].map((_, i) => {
    const ind = direction === 'left' ? i - start : i;
    return strConvert[ind] === undefined ? pad : strConvert[ind];
  });

  return arrPadding.join('');
};

export const isDateFormat = value => (/[2][0-9]{3}-([0][1-9]|[1][0-2])-([0][1-9]|[1-2][0-9]|[3][0-1])/.test(value));
export const html2json = () => {

};
