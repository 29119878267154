/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable arrow-parens */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { TextField, SelectField, Select, MenuItem } from 'material-ui';

import CardBox from 'components/CardBox';
import { SubmitButton, CancelButton } from 'components/Buttons';
import TagTable from './Table';

import API from '../../../../helper/api';

const api = new API();

export default class TagEditForm extends React.Component {

  constructor() {
    super();
    this.state = {
      name: '',
      parent: '',
      parent_id: '',
      tags: [],
      errMessage: '',
      resourceCreated: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
  }

  componentDidMount() {
    api
      .get(`/tags/${this.props.match.params.tagId}`)
      .then(response => {


        this.setState({
          name: response.data[0].tag_name,
          parent: response.data[0].parent_name ? response.data[0].parent_name : 'No Parent',
        });
      });

    api.get('/tags').then(res => {
      this.setState({
       tags: res.data.records
      });
    });

  }

  handleSubmit = e => {
    e.preventDefault();
    const { name, parent_id } = this.state;
    const { tagId: id } = this.props.match.params;

    if (name && name.trim()) {
      api
        .post(`/tags/${id}`, { id, name, parent_id })
        .then(response => {
          this.setState({ resourceCreated: true });
        });
    } else {
      this.setState({ errMessage: 'Please enter tag.' });
    }
  };

  handleNameChange = (e) => {
    this.setState({
        name: e.target.value
      });
  };

  handleParentChange(e) {
    const val = e.target.value;
    console.log(val);
    const parts = val.split(',');
    console.log(parts);
    this.setState({
        parent: parts[0],
        parent_id: parts[1]
      });
  }

  render() {
    const { errMessage, name, parent, resourceCreated } = this.state;

    if (resourceCreated) {
      return <Redirect to={'/app/tags'} />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <CardBox styleName="col-md-6 m-auto">
            <div>
              {errMessage && <p className="text-danger">{errMessage}</p>}
              <form onSubmit={this.handleSubmit}>
                <fieldset>
                  <TextField
                    id="tag"
                    name="tag"
                    value={name}
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleNameChange}
                  />

                  <Select
                    fullWidth
                    margin="normal"
                    className="mt-1"
                    value={parent}
                    onChange={this.handleParentChange}
                  >

                    {this.state.tags.map((val, index) => <MenuItem key={index} value={`${val.tag_name},${val.id}`}>{val.tag_name}</MenuItem>)}
                  </Select>
                  <label>Parent : {parent}</label>
                  <SubmitButton />
                  <CancelButton to="/app/tags" />
                </fieldset>
              </form>
            </div>
          </CardBox>
        </div>
      </div>
    );
  }
}
