import {
  REQUEST_SERVICES,
  RECEIVE_SERVICES,
} from 'actions/ActionType';

const INIT_STATE = {
  services: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_SERVICES:
      return {
        ...state,
        isFetching: true
      }
    case RECEIVE_SERVICES:
      return {
        ...state,
        isFetching: false,
        services: action.services
      }
    default:
      return state;
  }
}
