import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchResource, updateResource, clearResource } from 'actions/Resource';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';

import TagTableCell from './TagTableCell';

class TagTable extends Component {
  state = {
    page: 1,
    totalEntries: 0,
  };

  componentDidMount() {
    const { location, fetchResource } = this.props;
    const query = new URLSearchParams(location.search);
    fetchResource(`tags?${query.toString()}`).then((response) => {
      this.setState({
        page: parseInt(query.get('page'), 10) || 1,
        totalEntries: response.totalEntries,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { location, fetchResource } = this.props;
    const { location: nextLocation } = nextProps;
    const query = new URLSearchParams(location.search);
    const nextQuery = new URLSearchParams(nextLocation.search);

    if (query.get('page') !== nextQuery.get('page')) {
      fetchResource(`tags?${nextQuery.toString()}`).then((response) => {
        this.setState({
          page: parseInt(nextQuery.get('page'), 10),
          location: nextLocation,
        });
      });
    }
  }

  componentWillUnmount() {
    this.props.clearResource();
  }

  updateTagStatus = (e) => {
    this.props.updateResource('tags', e.currentTarget.dataset);
  };

  render() {
    if (this.props.isFetching) {
      return <Loader />;
    }

    return (
      <div className="row mb-md-4">
        <div className="col-12">
          <div className="jr-card">
            <div className="table-responsive-material">
              <Link
                className="float-right jr-btn bg-primary text-white"
                to="/app/tags/new"
                style={{ textDecoration: 'none' }}
              >
                Create Tags
              </Link>
              <table className="default-table table-unbordered table table-sm table-hover">
                <thead className="th-border-b">
                  <tr>
                    <th>Tag Name</th>
                    <th>Tag's Parent Name</th>
                    <th className="status-cell text-right">Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.props.items &&
                    this.props.items.map((tag, index) => (
                      <TagTableCell
                        key={index}
                        tag={tag}
                        onStatusChange={this.updateTagStatus}
                      />
                    ))}
                </tbody>
              </table>
            </div>
            <div className="mt-5 float-right">
              <Pagination
                location={this.props.location}
                currentPage={this.state.page}
                totalEntries={this.state.totalEntries}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ resource }) => ({
  items: resource.items,
  isFetching: resource.isFetching,
});

export default connect(mapStateToProps, {
  fetchResource,
  updateResource,
  clearResource,
})(TagTable);
