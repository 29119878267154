/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prefer-const */
/* eslint-disable one-var */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';
import Button from 'material-ui/Button';

export default class TagTableCell extends PureComponent {
  state = {
    anchorEl: undefined,
    open: false,
  };

  openMenu = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  handleClick = (e) => {
    this.closeMenu();
    this.props.onStatusChange(e);
  };

  render() {
    const { deleteSMS, tag, ids } = this.props;


    return (
      <tr>
        <td style={{width: '50px'}}>
          <input
            checked={!!ids.find(val => val == tag.id)}
            type="checkbox"
            value={tag.id}
            onChange={(e) => {
              const value = e.target.value; console.log(value);
              this.props.addMarkedSms(value);
            }}
          />

        </td>


        <td style={{width: '150px'}}>{tag.sms_sender}</td>
        <td>{tag.sms_body}</td>
        <td style={{width: '150px', textAlign: 'right'}}>{(() => {
          let text = tag.date_received;
          let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'August', 'Sept', 'Oct', 'Nov', 'Dec'];
          let d = new Date(text);
          return `${d.getDate()}, ${months[d.getMonth()]}  ${d.getFullYear()}`;
        })()}</td>

      </tr>
    );
  }
}
