import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from 'material-ui/Progress';

import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import { Button } from 'material-ui';

import { displaySuccessAlert, displayFailureAlert } from 'actions/Alert';

import API from './../../../../../helper/api';


const api = new API();
class NewConfig extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      errMessage: '',
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { file } = this.state;

    if (file) {
      const formData = new FormData();
      formData.append('config', file);

      api
        .post('/config', formData)
        .then((response) => {
          this.props.displaySuccessAlert(response.data);
          this.form.reset();
          this.setState({
            file: null,
            errMessage: '',
          });
        })
        .catch(({ response }) => {
          const message = response ? response.data : 'Something went wrong!';
          this.props.displayFailureAlert(message);
        });
    } else {
      this.setState({ errMessage: 'Please select a file' });
    }
  };

  handleFileInputChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  render() {
    const { errMessage, isLoading } = this.state;
    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader match={this.props.match} title="Upload Config" />
        <div className="row mb-md-4">
          <CardBox styleName="col-md-4 col-12 m-auto" heading="Upload Config">
            <div>
              {errMessage && <p className="text-danger">{errMessage}</p>}
            </div>
            <form
              ref={(form) => {
                this.form = form;
              }}
              onSubmit={this.handleSubmit}
              encType="multipart/form-data"
            >
              <input type="hidden" name="_csrf" />
              <fieldset>
                <input type="file" onChange={this.handleFileInputChange} />
                <Button
                  raised
                  type="submit"
                  className="jr-btn text-white"
                  color="primary"
                >
                  Upload
                  {isLoading && (
                    <CircularProgress
                      style={{
                        width: 18,
                        height: 18,
                        marginLeft: 13,
                        verticalAlign: 'middle',
                      }}
                    />
                  )}
                </Button>
              </fieldset>
            </form>
          </CardBox>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { displaySuccessAlert, displayFailureAlert },
)(NewConfig);
