import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';

export default class MessageTableCell extends PureComponent {
  state = {
    anchorEl: undefined,
    open: false,
  };

  openMenu = event => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  handleClick = e => {
    this.closeMenu();
    this.props.onSendMessage(e);
  };

  render() {
    const { message, onSendMessage } = this.props;
    let statusColor, statusText;

    if (message.status) {
      statusColor = 'success';
      statusText = 'Sent';
    } else {
      statusColor = 'danger';
      statusText = 'Unsent';
    }

    return (
      <tr>
        <td>{message.title}</td>
        <td>{message.createdAt}</td>
        <td>{message.sentAt}</td>
        <td>{message.totalUsers}</td>
        <td className="status-cell text-right">
          <Badge color={statusColor}>{statusText}</Badge>
        </td>
        <td className="text-right">
          <IconButton className="size-30" onClick={this.openMenu}>
            <i className="zmdi zmdi-more-vert" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.closeMenu}
            style={{ maxHeight: 40 * 4.5 }}
            MenuListProps={{ style: { width: 150 } }}
          >
            <Link
              to={`/app/messages/${message.id}`}
              style={{ textDecoration: 'none' }}
            >
              <MenuItem onClick={this.closeMenu}>Edit</MenuItem>
            </Link>
            <MenuItem data-id={message.id} onClick={this.handleClick}>
              Send
            </MenuItem>
          </Menu>
        </td>
      </tr>
    );
  }
}
