import React from 'react';
import Button from 'material-ui/Button';

const Footer = () => (
  <footer className="app-footer">
    <div className="d-flex flex-row justify-content-between">
      <div>
        <span> Copyright KliQr &copy; { new Date().getFullYear() }</span>
      </div>
    </div>
  </footer>
);

export default Footer;
