import {
  ADD_FAVOURITE,
  FILTER_CONTACT,
  GET_ALL_CONTACT,
  GET_UNSELECTED_ALL_CONTACT,
  HANDLE_REQUEST_CLOSE,
  ON_ADD_CONTACT,
  ON_ALL_CONTACT_SELECT,
  ON_CONTACT_CLOSE,
  ON_CONTACT_SELECT,
  ON_DELETE_CONTACT,
  ON_DELETE_SELECTED_CONTACT,
  ON_FILTER_OPTION_SELECT,
  ON_SAVE_CONTACT,
  ON_TOGGLE_DRAWER,
  UPDATE_SEARCH_USER
} from 'actions/ActionType';


export const addFavourite = data => ({
  type: ADD_FAVOURITE,
  payload: data
});
export const onContactSelect = data => ({
  type: ON_CONTACT_SELECT,
  payload: data
});


export const onAddContact = () => ({
  type: ON_ADD_CONTACT,
  payload: ''
});
export const onContactClose = () => ({
  type: ON_CONTACT_CLOSE,
  payload: ''
});
export const onFilterOptionSelect = option => ({
  type: ON_FILTER_OPTION_SELECT,
  payload: option
});
export const onSaveContact = data => ({
  type: ON_SAVE_CONTACT,
  payload: data
});
export const onDeleteContact = data => ({
  type: ON_DELETE_CONTACT,
  payload: data
});
export const onDeleteSelectedContact = () => ({
  type: ON_DELETE_SELECTED_CONTACT,
  payload: ''
});
export const filterContact = userName => ({
  type: FILTER_CONTACT,
  payload: userName
});
export const getAllContact = () => ({
  type: GET_ALL_CONTACT,
  payload: ''
});
export const getUnselectedAllContact = () => ({
  type: GET_UNSELECTED_ALL_CONTACT,
  payload: ''
});
export const onAllContactSelect = () => ({
  type: ON_ALL_CONTACT_SELECT,
  payload: ''
});
export const updateContactUser = userName => ({
  type: UPDATE_SEARCH_USER,
  payload: userName
});
export const onToggleDrawer = () => ({
  type: ON_TOGGLE_DRAWER,
  payload: ''
});
export const handleRequestClose = () => ({
  type: HANDLE_REQUEST_CLOSE,
  payload: ''
});
