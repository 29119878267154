import { SUCCESS_ALERT, FAILURE_ALERT } from 'actions/ActionType';

const INIT_STATE = {
  shouldDisplayAlert: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUCCESS_ALERT:
      return {
        ...state,
        color: 'success',
        message: action.message,
        shouldDisplayAlert: true,
      };
    case FAILURE_ALERT:
      return {
        ...state,
        color: 'danger',
        message: action.message,
        shouldDisplayAlert: true,
      };
    default:
      return state;
  }
};
