import {
  GET_ALL_MAIL,
  GET_IMPORTANT_MAIL,
  GET_NAV_FILTERS,
  GET_NAV_FOLDER,
  GET_NAV_LABELS,
  GET_READ_MAIL,
  GET_STARRED_MAIL,
  GET_UNIMPORTANT_MAIL,
  GET_UNREAD_MAIL,
  GET_UNSELECTED_ALL_MAIL,
  GET_UNSTARRED_MAIL,
  HANDLE_REQUEST_CLOSE,
  ON_ALL_MAIL_SELECT,
  ON_COMPOSE_MAIL,
  ON_DELETE_MAIL,
  ON_FOLDER_MENU_ITEM_SELECT,
  ON_FOLDER_SELECT,
  ON_HIDE_LOADER,
  ON_IMPORTANT_SELECT,
  ON_LABEL_MENU_ITEM_SELECT,
  ON_LABEL_SELECT,
  ON_MAIL_CHECKED,
  ON_MAIL_SELECT,
  ON_MAIL_SEND,
  ON_OPTION_MENU_ITEM_SELECT,
  ON_OPTION_MENU_SELECT,
  ON_START_SELECT,
  ON_TOGGLE_DRAWER,
  SEARCH_MAIL,
  SET_CURRENT_MAIL_NULL,
  UPDATE_SEARCH
} from './ActionType';


export const onFolderMenuItemSelect = folderId => ({
  type: ON_FOLDER_MENU_ITEM_SELECT,
  payload: folderId
});

export const onMailLabelMenuItemSelect = label => ({
  type: ON_LABEL_MENU_ITEM_SELECT,
  payload: label
});
export const handleMailRequestClose = () => ({
  type: HANDLE_REQUEST_CLOSE,
});

export const getMailNavFilters = filter => ({
  type: GET_NAV_FILTERS,
  payload: filter,
});
export const onFolderSelect = () => ({
  type: ON_FOLDER_SELECT
});
export const onMailLabelSelect = () => ({
  type: ON_LABEL_SELECT,
});
export const onMailOptionMenuSelect = () => ({
  type: ON_OPTION_MENU_SELECT,
});
export const onOptionMenuItemSelect = option => ({
  type: ON_OPTION_MENU_ITEM_SELECT,
  payload: option
});
export const getAllMail = () => ({
  type: GET_ALL_MAIL,
});
export const getUnselectedAllMail = () => ({
  type: GET_UNSELECTED_ALL_MAIL,
});
export const getReadMail = () => ({
  type: GET_READ_MAIL
});
export const getUnreadMail = () => ({
  type: GET_UNREAD_MAIL
});
export const getStarredMail = () => ({
  type: GET_STARRED_MAIL
});

export const getUnStarredMail = () => ({
  type: GET_UNSTARRED_MAIL
});
export const getImportantMail = () => ({
  type: GET_IMPORTANT_MAIL
});
export const getUnimportantMail = () => ({
  type: GET_UNIMPORTANT_MAIL
});
export const getMailNavLabels = label => ({
  type: GET_NAV_LABELS,
  payload: label
});
export const onSearchMail = searchText => ({
  type: SEARCH_MAIL,
  payload: searchText
});
export const onMailChecked = data => ({
  type: ON_MAIL_CHECKED,
  payload: data
});

export const onAllMailSelect = () => ({
  type: ON_ALL_MAIL_SELECT
});
export const onStartSelect = data => ({
  type: ON_START_SELECT,
  payload: data
});
export const onImportantSelect = data => ({
  type: ON_IMPORTANT_SELECT,
  payload: data
});
export const onMailSend = data => ({
  type: ON_MAIL_SEND,
  payload: data
});
export const onMailSelect = mail => ({
  type: ON_MAIL_SELECT,
  payload: mail
});
export const getNavFolders = folder => ({
  type: GET_NAV_FOLDER,
  payload: folder
});

export const updateMailSearch = searchText => ({
  type: UPDATE_SEARCH,
  payload: searchText
});
export const onMailToggleDrawer = () => ({
  type: ON_TOGGLE_DRAWER
});
export const onDeleteMail = () => ({
  type: ON_DELETE_MAIL
});
export const hideMailLoader = () => ({
  type: ON_HIDE_LOADER
});
export const setCurrentMailNull = () => ({
  type: SET_CURRENT_MAIL_NULL
});
export const onComposeMail = () => ({
  type: ON_COMPOSE_MAIL
});
